import styled from '@emotion/styled';
import { Chip, Divider, Typography } from '@mui/material';

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const NameText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  @media screen and (min-width: 600px) {
    font-size: 20px;
  }
`;

export const BioText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const LocationText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const LabelText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 8px;
`;

export const SpecialitiesChip = styled(Chip)`
  margin: 5px;
  font-size: 14px;
  margin-left: 0;
  border-radius: 5px;
`;

export const BiographyText = styled(Typography)`
  white-space: pre-line;
  overflow: hidden;
`;

export const StyledList = styled.ul`
  margin: 0px;
  padding-inline-start: 20px;
  @media screen and (max-width: 600px) {
    padding-inline-start: 0px;
  }
`;

export const StyledListItem = styled.li`
  @media screen and (max-width: 600px) {
    list-style-type: none;
  }
`;

export const BulletText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
