import React, { useCallback, useState } from 'react';

import { useTheme } from '@emotion/react';
import { Grid, Stack } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { format, isBefore, isSameDay, setDate, startOfMonth } from 'date-fns';

import { useAvailableDates, useUser } from '../../api';
import { Provider } from '../../types/Provider';
import AppointmentTypeChip from '../AppointmentTypeChip/AppointmentTypeChip';
import BookingConfirmation from '../BookingConfirmation/BookingConfirmation';
import Modal from '../Modal/Modal';

import {
  BodyText,
  BoldText,
  BookCTA,
  BookingCardDesktop,
  BookingCardMobile,
  BookingContainer,
  CTAContainer,
  DisclaimerText,
  LabelText,
  StyledDivider,
  TimeChip,
  TitleText,
} from './Booking.styles';

const content = {
  title: 'Make an appointment with',
  appointmentTypeLabel: 'Appointment Type',
  appointmentTypeNote: 'Note: All appointments are approximately 1 hour',
  dateAndTimeLabel: 'Date & Time',
  morningLabel: 'Morning',
  afternoonLabel: 'Afternoon',
  eveningLabel: 'Evening',
  selectedAppointmentLabel: 'Selected Date & Time',
  ctaText: 'Continue with selection',
  bookingCTAText: 'Book Appointment',
};

interface BookingProps {
  provider: Provider;
}

const Booking = ({ provider }: BookingProps) => {
  const [isOpenBookingModal, setIsOpenBookingModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<Date>(startOfMonth(new Date()));
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(
    provider.bookingTypesAvailable[0]
  );
  const [selectedAppointment, setSelectedAppointment] = useState('');

  const lastDateOfPreviousMonth = setDate(new Date(selectedMonth), 0);
  const { availableDates } = useAvailableDates(provider.providerId, lastDateOfPreviousMonth);
  const { user } = useUser();
  const theme = useTheme();

  const isMobile = window.innerWidth < 600;

  const selectAppointmentType = useCallback((type: string) => {
    setSelectedAppointmentType(type);
  }, []);

  const openBookingModal = useCallback(() => {
    setIsOpenBookingModal(true);
  }, [setIsOpenBookingModal]);

  const closeBookingModal = useCallback(() => {
    setIsOpenBookingModal(false);
  }, [setIsOpenBookingModal]);

  const openConfirmModal = useCallback(() => {
    closeBookingModal();
    setIsOpenConfirmModal(true);
  }, [setIsOpenConfirmModal]);

  const closeConfirmModal = useCallback(() => {
    closeBookingModal();
    setIsOpenConfirmModal(false);
  }, [setIsOpenConfirmModal]);

  const handleMonthChange = (month: Date) => {
    setSelectedMonth(month);
    setSelectedDate(null);
    setSelectedTime('');
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      if (isBefore(date, new Date())) {
        date = new Date();
      }
      setSelectedDate(date);
      setSelectedTime('');
    }
  };

  const handleTimeSelected = (date: string) => {
    setSelectedTime(date);
    if (selectedDate) {
      const dateObject = new Date(date);
      const chosenDate = format(dateObject, 'E, d MMMM y');
      const chosenTime = format(dateObject, 'h:mm:a');

      setSelectedAppointment(`${chosenDate} at ${chosenTime}`);
    }
  };

  const DaySlot = (props: PickersDayProps<Date>) => {
    const availableDate = availableDates?.find((a) => isSameDay(new Date(a.date), props.day));
    const times = availableDate?.times.length ?? 0;
    return (
      <PickersDay
        {...props}
        sx={{
          background: (t) => (times > 0 && !props.disabled ? t.palette.primary.light : undefined),
        }}
      />
    );
  };

  const getTimeChipsBetweenHoursOf = (min: number, max: number) => {
    if (!selectedDate || !availableDates) {
      // TODO: Show loading state
      return <></>;
    }

    const availableDate = availableDates.find((a) => isSameDay(new Date(a.date), selectedDate));
    const times = availableDate?.times;

    return times
      ?.filter((item) => {
        const date = new Date(item);
        const hour = date.getHours();
        if (hour >= min && hour < max) {
          return true;
        }

        return false;
      })
      .map((date) => {
        const isSelectedTime = selectedTime === date;
        return (
          <TimeChip
            key={date}
            variant={isSelectedTime ? 'outlined' : 'filled'}
            label={format(new Date(date), 'h:mm a')}
            onClick={() => handleTimeSelected(date)}
            disabled={isBefore(new Date(date), new Date())}
          />
        );
      });
  };

  const {
    firstName,
    lastName,
    type,
    bookingTypesAvailable,
    providerId,
    avatar,
    address,
    city,
    suburb,
    unitsPerSession,
  } = provider;

  const BookingCard = () => (
    <BookingContainer>
      <TitleText>{`${content.title} ${firstName}`}</TitleText>
      <LabelText>{content.appointmentTypeLabel}</LabelText>
      <Stack direction="row" spacing={1} justifyContent="center">
        {bookingTypesAvailable.map((bookingType) => (
          <AppointmentTypeChip
            stroke={theme.palette.secondary.contrastText}
            key={bookingType}
            type={bookingType}
            label={bookingType}
            selected={bookingType === selectedAppointmentType}
            onClick={selectAppointmentType}
          />
        ))}
      </Stack>
      <DisclaimerText>{content.appointmentTypeNote}</DisclaimerText>
      <StyledDivider />
      <LabelText>{content.dateAndTimeLabel}</LabelText>
      <DateCalendar
        views={['day']}
        defaultCalendarMonth={selectedMonth}
        onMonthChange={handleMonthChange}
        onChange={handleDateChange}
        value={selectedDate}
        slots={{ day: DaySlot }}
        disablePast
        disableHighlightToday
        sx={{
          width: 'auto',
          maxWidth: '320px',
          '.MuiPickersDay-root': {
            borderRadius: '4px',
            '&.Mui-selected': {
              backgroundColor: 'transparent !important',
              border: '1px solid',
              borderColor: (t) => t.palette.primary.main,
              color: (t) => t.palette.secondary.contrastText,
            },
          },
        }}
      />
      <Grid container sx={{ marginBottom: '32px' }}>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <BoldText gutterBottom>{content.morningLabel}</BoldText>
          {getTimeChipsBetweenHoursOf(0, 12)}
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <BoldText gutterBottom>{content.afternoonLabel}</BoldText>
          {getTimeChipsBetweenHoursOf(12, 17)}
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <BoldText gutterBottom>{content.eveningLabel}</BoldText>
          {getTimeChipsBetweenHoursOf(17, 24)}
        </Grid>
      </Grid>
      <StyledDivider />
      {selectedTime && (
        <>
          <LabelText>{content.selectedAppointmentLabel}</LabelText>
          <BodyText>{selectedAppointment}</BodyText>
        </>
      )}
      <CTAContainer>
        <BookCTA
          variant="contained"
          disabled={!selectedTime || !selectedAppointmentType}
          onClick={openConfirmModal}
        >
          {content.ctaText}
        </BookCTA>
      </CTAContainer>
    </BookingContainer>
  );

  return (
    <>
      <BookingCardDesktop>
        <BookingCard />
      </BookingCardDesktop>
      <BookingCardMobile>
        <BookCTA variant="contained" onClick={openBookingModal}>
          {content.bookingCTAText}
        </BookCTA>
      </BookingCardMobile>
      <Modal fullHeight isOpen={isOpenBookingModal} onClose={closeBookingModal}>
        <BookingCard />
      </Modal>
      <Modal fullHeight={isMobile} isOpen={isOpenConfirmModal} onClose={closeConfirmModal}>
        <BookingConfirmation
          appointment={{
            providerId: providerId,
            providerFirstName: firstName,
            providerLastName: lastName,
            providerType: type,
            providerImageUrl: avatar,
            addressLine1: address,
            suburb: suburb || '',
            city: city,
            unitsPerSession: unitsPerSession,
            appointmentType: selectedAppointmentType,
            duration: 60,
            appointmentDate: `${selectedTime}`,
            appointmentStart: `${selectedTime}`,
            appointmentEnd: '', // TODO:
          }}
          userPhoneNumber={user?.phone || ''}
          onClose={closeConfirmModal}
        />
      </Modal>
    </>
  );
};

export default Booking;
