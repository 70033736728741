import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

export const AppointmentContainer = styled(Box)`
  width: 100%;
  padding-bottom: 64px;
`;

export const TitleText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  text-align: center;
  margin-bottom: 54px;
`;
