import React from 'react';

import { useMediaQuery } from '@mui/material';

import Filter from './Filter';
import { FilterContainer, FilterTitle } from './Filter.styles';
import MobileFilter from './MobileFilter';

const content = {
  label: 'Location',
  placeholder: 'Show All Locations',
};

interface LocationFilterProps {
  onChange: (option: Array<string>) => void;
  values: Array<string>;
  options?: Array<string>;
}

const LocationFilter = ({ onChange, values, options }: LocationFilterProps) => {
  const isDesktop = useMediaQuery('(min-width:900px)');

  if (!options) {
    return <></>;
  }

  return (
    <FilterContainer>
      <FilterTitle>{content.label}</FilterTitle>
      {isDesktop ? (
        <Filter options={options} label={content.placeholder} onChange={onChange} values={values} />
      ) : (
        <MobileFilter
          options={options}
          label={content.placeholder}
          onChange={onChange}
          values={values}
        />
      )}
    </FilterContainer>
  );
};

export default LocationFilter;
