import styled from '@emotion/styled';
import { Button, Divider, Typography } from '@mui/material';

export const TitleText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.25px;
`;

export const SubtitleText = styled(Typography)`
  margin-top: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.44px;
`;

export const BodyText = styled(Typography)`
  margin-top: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  opacity: 0.6;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 16px;
`;

export const QuestionText = styled(Typography)`
  margin-top: 24px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const SubmitContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  text-align: center;
`;

export const SubmitButton = styled(Button)`
  padding: 16px 56px;
  background: ${({ theme }) => theme.palette.primary.main};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-transform: none;
`;
