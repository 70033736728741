import React, { SyntheticEvent } from 'react';

import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import { Checkbox, FormControl, TextField, Typography } from '@mui/material';

import { PlaceholderText, StyledAutocomplete, StyledListItem } from './Filter.styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface FiltersProps {
  options: Array<string>;
  label: string;
  onChange?: (option: Array<string>) => void;
  values: Array<string>;
}

const Filter = ({ options, label, onChange, values }: FiltersProps) => {
  const handleOnChange = (_event: SyntheticEvent, value: unknown) => {
    if (onChange) {
      onChange(value as string[]);
    }
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <StyledAutocomplete
        value={values}
        multiple
        onChange={handleOnChange}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option as string}
        renderTags={() => <PlaceholderText>{label}</PlaceholderText>}
        renderOption={(props, option, { selected }) => (
          <StyledListItem {...props}>
            <Typography sx={{ width: '100%' }}>{option as string}</Typography>
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          </StyledListItem>
        )}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </FormControl>
  );
};

export default Filter;
