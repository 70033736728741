import React from 'react';

import { useTheme } from '@emotion/react';
import { Avatar, Card, Grid } from '@mui/material';

import config from '../../config.json';
import LocationIcon from '../../icons/LocationIcon';
import { Provider } from '../../types/Provider';

import {
  AvatarContainer,
  BioText,
  BiographyText,
  BulletText,
  LabelText,
  LocationText,
  NameText,
  SpecialitiesChip,
  StyledDivider,
  StyledList,
  StyledListItem,
} from './ProviderDetail.styles';

interface ProviderProps {
  data: Provider;
}

const ProviderDetail = ({ data }: ProviderProps) => {
  const theme = useTheme();
  const {
    providerId,
    firstName,
    lastName,
    type,
    avatar,
    suburb,
    city,
    biography,
    specialties,
    qualificationsExtended,
  } = data;

  const initials = `${firstName[0]}${lastName[0]}`;

  return (
    <Card sx={{ padding: '20px' }}>
      <AvatarContainer>
        <Avatar
          src={avatar}
          alt={initials}
          sx={{ bgcolor: theme.palette.primary.main, width: 80, height: 80 }}
        >
          {!avatar && initials.toUpperCase()}
        </Avatar>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          sx={{
            paddingLeft: '16px',
            alignItems: 'flex-start',
            '@media (max-width: 600px)': {
              paddingLeft: 0,
              alignItems: 'center',
            },
          }}
        >
          <Grid item>
            <NameText>{`${firstName} ${lastName}`}</NameText>
          </Grid>
          <Grid item>
            <BioText>{`${type}`}</BioText>
          </Grid>
          <Grid item>
            <Grid container direction="row" sx={{ flexWrap: 'noWrap' }}>
              <Grid item sx={{ marginRight: '5px', marginTop: '1px' }}>
                <LocationIcon fill={theme.palette.primary.main} />
              </Grid>
              <Grid item>
                <LocationText>{suburb ? `${suburb}, ${city}` : city}</LocationText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AvatarContainer>
      <StyledDivider />
      {!config.providers.hideSpecialties && (
        <>
          <LabelText>Specialties</LabelText>
          {specialties.map((speciality) => (
            <SpecialitiesChip key={speciality} label={speciality} variant="outlined" />
          ))}
          <StyledDivider />
        </>
      )}
      <LabelText>Biography</LabelText>
      <BiographyText>{biography.split('\r\n\r\n').join('\n\n')}</BiographyText>
      {qualificationsExtended && (
        <>
          <StyledDivider />
          <LabelText>Qualifications</LabelText>
          <StyledList>
            {qualificationsExtended.split('\r\n').map((qualification, index) => (
              <StyledListItem key={`${providerId}-qual-${index}`}>
                <BulletText>{qualification.trim()}</BulletText>
              </StyledListItem>
            ))}
          </StyledList>
        </>
      )}
    </Card>
  );
};

export default ProviderDetail;
