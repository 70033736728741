import styled from '@emotion/styled';
import { Autocomplete, List, ListItem, Paper, Typography } from '@mui/material';

export const FilterContainer = styled(Paper)`
  width: 100%;
  padding-bottom: 20px;
  align-items: center;
  @media screen and (min-width: 900px) {
    padding: 20px;
  }
`;

export const FilterTitle = styled(Typography)`
  width: 100%;
  padding-left: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const StyledAutocomplete = styled(Autocomplete)({
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputLabel-shrink': {
    display: 'none',
    transform: 'none',
  },
});

export const PlaceholderText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledList = styled(List)`
  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const StyledListItem = styled(ListItem)`
  display: flex;
  width: 100%;
`;
