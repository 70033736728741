import styled from '@emotion/styled';

import config from '../../config.json';

export const Container = styled.div`
  ${({ theme }) => {
    // Define a function to safely attempt access to nested properties
    function getBackgroundImageUrl() {
      // Safely check if the nested property exists
      if (config && config.providers && 'backgroundImageUrl' in config.providers) {
        return config.providers.backgroundImageUrl;
      }
      // Return a safe fallback if the property does not exist
      return null;
    }

    const backgroundImageUrl = getBackgroundImageUrl();
    const baseColor = theme.palette.primary.main;

    // Setup background styles conditionally based on the presence of an image URL
    const backgroundStyles = backgroundImageUrl
      ? `background: linear-gradient(
          90deg,
          rgba(${parseInt(baseColor.slice(1, 3), 16)}, ${parseInt(
          baseColor.slice(3, 5),
          16
        )}, ${parseInt(baseColor.slice(5, 7), 16)}, 1) 0%,
          rgba(${parseInt(baseColor.slice(1, 3), 16)}, ${parseInt(
          baseColor.slice(3, 5),
          16
        )}, ${parseInt(baseColor.slice(5, 7), 16)}, 0.33) 97.4%
        ), url('${backgroundImageUrl}') no-repeat center center / cover;`
      : `background-color: ${baseColor};`;

    return `
      position: relative;
      z-index: 5;
      ${backgroundStyles}
      height: 160px;
      width: 100%;
      @media screen and (min-width: 600px) {
        height: 400px;
      }
    `;
  }}
`;
