import React from 'react';

import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Typography,
  Checkbox,
  TextField,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';

import { StyledListItem } from './Filter.styles';
import { GroupedFilterProps } from './types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function GroupedFilter({ options, label, onChange, values, renderIcon }: GroupedFilterProps) {
  const selectedValues = options.filter((option) => values.includes(option.name));

  return (
    <div>
      <Autocomplete
        multiple
        options={options}
        groupBy={(option) => option.group}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        renderTags={() => <Typography>{label}</Typography>}
        renderOption={(props, option, { selected }) => (
          <StyledListItem {...props}>
            <ListItemIcon> {renderIcon(option)}</ListItemIcon>
            <ListItemText>
              <Typography sx={{ width: '100%' }}>{option.name}</Typography>
            </ListItemText>
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          </StyledListItem>
        )}
        renderInput={(params) => <TextField {...params} label={label} />}
        value={selectedValues}
        renderGroup={({ group, children }) => (
          <li>
            <ListSubheader component="div" sx={{ fontWeight: 'bold' }}>
              {group}
            </ListSubheader>
            {children}
          </li>
        )}
        onChange={(event, newValue) => {
          const newValues = newValue.map((item) => item.name);
          onChange(newValues);
        }}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputLabel-shrink': {
            display: 'none',
            transform: 'none',
          },
        }}
      />
    </div>
  );
}
export default GroupedFilter;
