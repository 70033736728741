import React, { ChangeEvent, useCallback, useState } from 'react';

import { Card } from '@mui/material';

import { UserDetails } from '../../types/User';
import { validateEmail } from '../../utils/validation';
import FormInput from '../FormInput/FormInput';

import {
  BodyText,
  CancelCTA,
  StyledContainer,
  StyledDivider,
  TitleText,
  UpdateCTA,
  UpdateChangesContainer,
} from './PersonalCommunications.styles';

const content = {
  title: 'Personal Communication',
  emailBody:
    'Receive confirmations and booking correspondence privately instead of using your account email.',
  emailLabel: 'Private email',
  emailError: 'Please enter your email',
  phoneNumberBody:
    "Only the person you've booked an appointment with will be able to see your phone number.",
  phoneNumberLabel: 'Phone number',
  cancelCTAText: 'Cancel',
  updateCTAText: 'Save changes',
};

interface PersonalCommunicationProps {
  userEmail: string;
  userPhoneNumber: string;
  saveUser: (userDetails: UserDetails) => void;
  isSavingUser: boolean;
}

const PersonalCommunications = ({
  userEmail,
  userPhoneNumber,
  saveUser,
  isSavingUser,
}: PersonalCommunicationProps) => {
  const [email, setEmail] = useState(userEmail);
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber);
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value === '') {
        setEmailError('');
      }
      setEmail(value);
    },
    [setEmail]
  );

  const handlePhoneNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/\D/g, '');
      setPhoneNumber(value);
    },
    [setPhoneNumber]
  );

  const getCommunicationHasChanged = () => {
    return email !== userEmail || phoneNumber !== userPhoneNumber;
  };

  const handleCancelOnClick = useCallback(() => {
    setEmail(userEmail);
    setPhoneNumber(userPhoneNumber);
    setEmailError('');
  }, [userEmail, userPhoneNumber, setEmail, setPhoneNumber]);

  const handleSaveOnClick = useCallback(() => {
    const isValidEmail = email === '' || validateEmail(email);

    if (isValidEmail) {
      setEmailError('');
      saveUser({ secondaryEmail: email, phone: phoneNumber });
    } else {
      setEmailError(content.emailError);
    }
  }, [emailError, email, phoneNumber, validateEmail, setEmailError]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        padding: '16px',
      }}
    >
      <TitleText>{content.title}</TitleText>
      <StyledDivider />
      <StyledContainer>
        <BodyText>{content.emailBody}</BodyText>
        <FormInput
          shrink
          label={content.emailLabel}
          error={emailError}
          onChange={handleEmailChange}
          value={email}
        />
      </StyledContainer>
      <StyledContainer>
        <BodyText>{content.phoneNumberBody}</BodyText>
        <FormInput
          shrink
          label={content.phoneNumberLabel}
          onChange={handlePhoneNumberChange}
          value={phoneNumber}
        />
      </StyledContainer>
      {getCommunicationHasChanged() && (
        <UpdateChangesContainer>
          <CancelCTA variant="outlined" onClick={handleCancelOnClick} disabled={isSavingUser}>
            {content.cancelCTAText}
          </CancelCTA>
          <UpdateCTA variant="contained" onClick={handleSaveOnClick} loading={isSavingUser}>
            {content.updateCTAText}
          </UpdateCTA>
        </UpdateChangesContainer>
      )}
    </Card>
  );
};

export default PersonalCommunications;
