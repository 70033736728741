import React from 'react';

import { Appointment } from '../../types/Appointment';
import AppointmentCard from '../AppointmentCard/AppointmentCard';

import { AppointmentContainer, TitleText } from './AppointmentDetails.styles';

interface AppointmentDetailsProps {
  appointment: Appointment;
}

const content = {
  title: 'Appointment Details',
  viewProfileLink: 'View profile',
  appointmentTypeLabel: 'Apppointment Type',
  dateAndTimeLabel: 'Date & Time',
  locationLabel: 'Location',
};

const AppointmentDetails = ({ appointment }: AppointmentDetailsProps) => {
  if (!appointment) {
    return <></>;
  }

  return (
    <AppointmentContainer>
      <TitleText>{content.title}</TitleText>
      <AppointmentCard appointment={appointment} />
    </AppointmentContainer>
  );
};

export default AppointmentDetails;
