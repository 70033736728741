interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
}

const LocationIcon = ({ fill = '#9197AF', width = '12', height = '15' }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M6 15s6-5.465 6-8.857C12 2.75 9.314 0 6 0S0 2.75 0 6.143C0 9.535 6 15 6 15Z"
      clipRule="evenodd"
    />
    <path fill="#fff" fillRule="evenodd" d="M6 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" clipRule="evenodd" />
  </svg>
);

export default LocationIcon;
