import axios from 'axios';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { RequestTokens, RequestTokensPayload, User, UserDetails } from '../types/User';

import { fetcher } from './fetcher';

export const useUser = () => {
  const { data, isLoading, mutate } = useSWR<User>('/User', fetcher);

  const { trigger: saveUser, isMutating: isSavingUser } = useSWRMutation(
    '/User',
    (url: string, { arg }: { arg: UserDetails }) => axios.post(url, arg).then(() => mutate())
  );

  return {
    user: data,
    isLoading,
    saveUser,
    isSavingUser,
  };
};

export const useTokens = () => {
  const { data, isLoading, mutate } = useSWR<RequestTokens[]>('/User/requestTokens', fetcher);

  const requestTokens = async (requestTokensPayload: RequestTokensPayload) => {
    await axios.post('/User/requestTokens', requestTokensPayload);
    // TODO: Add trigger from SWR to allow loading/error from POST
    mutate();
  };

  return {
    data: data,
    isLoading,
    requestTokens,
  };
};

export const test = {};
