import React from 'react';

import { Container, Grid } from '@mui/material';

import { useAppointments } from '../../api';
import CurrentBookings from '../../components/CurrentBookings/CurrentBookings';
import MyAccount from '../../components/MyAccount/MyAccount';
import PastBookings from '../../components/PastBookings/PastBookings';
import Splash from '../../components/Splash/Splash';
import config from '../../config.json';

import { Title } from './Dashboard.styles';

const Dashboard = () => {
  const {
    currentAppointments,
    pastAppointments,
    isLoading: isLoadingAppointments,
  } = useAppointments();

  return (
    <>
      <Splash>
        <Container sx={{ paddingTop: '2%' }}>
          <Title>{config.dashboard.title}</Title>
        </Container>
      </Splash>
      <Container
        sx={{
          position: 'relative',
          zIndex: 6,
          '@media (min-width: 600px)': {
            marginTop: '-150px',
          },
          paddingBottom: '120px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <CurrentBookings appointments={currentAppointments} isLoading={isLoadingAppointments} />
          </Grid>
          <Grid item md={6} xs={12}>
            <PastBookings appointments={pastAppointments} isLoading={isLoadingAppointments} />
          </Grid>
          <Grid item xs={12}>
            <MyAccount />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
