import React, { useCallback } from 'react';

import config from '../../config.json';

import {
  BodyContainer,
  BodyText,
  CancellationContainer,
  CTA,
  PhoneNumberText,
  TitleText,
} from './Cancellation.styles';

const content = {
  title: 'Cancellation Policy',
  body1: `You can cancel an appointment up to ${config.cancellationWindow} hours before its due${
    config.sessions.hideSessions ? '' : ' and receive a full refund'
  }.`,
  body2: `If you would still like to cancel or amend your appointment, call ${config.orgSupport} on `,
  contactNumber: config.contactNumber,
  ctaText: 'Done',
};

interface CancellationPolicyProps {
  onClose: () => void;
}

const CancellationPolicy = ({ onClose }: CancellationPolicyProps) => {
  const handleDoneCtaOnClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <CancellationContainer>
      <TitleText>{content.title}</TitleText>
      <BodyContainer>
        <BodyText>
          {content.body1}
          <br />
          <br />
          {content.body2}{' '}
          <PhoneNumberText type="tel" href={`tel:${content.contactNumber}`}>
            {content.contactNumber}
          </PhoneNumberText>
          .
        </BodyText>
      </BodyContainer>
      <CTA variant="contained" fullWidth onClick={handleDoneCtaOnClick}>
        {content.ctaText}
      </CTA>
    </CancellationContainer>
  );
};

export default CancellationPolicy;
