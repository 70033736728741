import React from 'react';

import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import { Checkbox, Typography } from '@mui/material';

import { StyledList, StyledListItem } from './Filter.styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface FiltersProps {
  options: Array<string>;
  label: string;
  onChange?: (option: Array<string>) => void;
  values: Array<string>;
}

const MobileFilter = ({ options, onChange, values }: FiltersProps) => {
  const handleCheckboxOnChange = (option: string) => {
    const currentIndex = values.indexOf(option);
    const newValues = [...values];

    if (currentIndex === -1) {
      newValues.push(option);
    } else {
      newValues.splice(currentIndex, 1);
    }

    if (onChange) {
      onChange(newValues);
    }
  };

  return (
    <StyledList>
      {options.map((option) => (
        <StyledListItem key={option}>
          <Checkbox
            icon={icon}
            onChange={() => handleCheckboxOnChange(option)}
            checkedIcon={checkedIcon}
            checked={values.indexOf(option) !== -1}
          />
          <Typography sx={{ width: '100%' }}>{option as string}</Typography>
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export default MobileFilter;
