import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const LinkText = styled(NavLink)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 10px 20px;
  text-decoration: none;
`;

export const DrawerIconContainer = styled.div`
  padding: 12px;
`;

export const DrawerLinkText = styled(NavLink)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  padding: 10px 20px;
  text-decoration: none;
`;
