import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const TitleText = styled(Typography)`
  margin-top: 42px;
  margin-bottom: 42px;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
`;
