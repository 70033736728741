import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, Link, Typography } from '@mui/material';

export const TitleText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 24px;
`;

export const BannerWrapper = styled.div`
  width: 100%;
  max-width: 461px;
  display: flex;
  margin-top: 30px;
  height: auto;

  @media screen and (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 8px;
  }
`;

export const EmailBanner = styled.div`
  width: 100%;
  max-width: 461px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.palette.info.main};
  border-left: 5px solid ${({ theme }) => theme.palette.primary.main};
  height: 55px;
`;

export const EmailBannerText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

export const PasswordContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CancelCTA = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 172px;
  margin-left: 15px;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  height: 50px;
  font-size: 14px;
  text-transform: none;

  @media screen and (max-width: 420px) {
    width: 100%;
    margin: 0px;
    margin-bottom: 16px;
  }
`;

export const UpdateCTA = styled(LoadingButton)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  width: 170px;
  height: 50px;
  margin-left: 15px;
  font-size: 14px;
  box-shadow: none;
  text-transform: inherit;
  background-color: ${({ theme }) => theme.palette.primary.main};

  @media screen and (max-width: 420px) {
    width: 100%;
    margin: 0px;
  }
`;

export const UpdateChangesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 25px;

  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`;

export const PasswordLabel = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const ResetPasswordLink = styled(Link)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  padding: 0px 20px;

  &:hover {
    cursor: pointer;
  }
`;
