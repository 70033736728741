import React, { ChangeEvent, useCallback, useState } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import { Card, Grid, InputAdornment } from '@mui/material';

import { UserDetails } from '../../types/User';
import FormInput from '../FormInput/FormInput';

import {
  BannerWrapper,
  CancelCTA,
  EmailBanner,
  EmailBannerText,
  StyledDivider,
  TitleText,
  UpdateCTA,
  UpdateChangesContainer,
} from './AccountDetails.styles';

const content = {
  title: 'Account Details',
  emailLabel: 'Account email',
  nameError: 'Please enter your name',
  emailBannerText: "You can't change your Account email because your account is connected to it.",
  firstNameLabel: 'First name',
  lastNameLabel: 'Last name',
  passwordLabel: 'Password',
  resetPasswordText: 'Reset Password',
  cancelCTAText: 'Cancel',
  updateCTAText: 'Save changes',
};

interface AccountDetailsProps {
  userFirstName: string;
  userLastName: string;
  primaryEmail?: string;
  saveUser: (userDetails: UserDetails) => void;
  isSavingUser: boolean;
}

const AccountDetails = ({
  userFirstName,
  userLastName,
  primaryEmail,
  saveUser,
  isSavingUser,
}: AccountDetailsProps) => {
  const [errors, setErrors] = useState({ firstName: '', lastName: '' });
  const [firstName, setFirstName] = useState(userFirstName);
  const [lastName, setLastName] = useState(userLastName);

  const handleFirstNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFirstName(event.target.value);
    },
    [setFirstName]
  );

  const handleLastNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLastName(event.target.value);
    },
    [setLastName]
  );

  const getNameHasChanged = () => {
    const firstNameHasChanged = firstName !== userFirstName;
    const lastNameHasChanged = lastName !== userLastName;
    if (errors.firstName !== '' && !firstNameHasChanged) {
      setErrors({ ...errors, firstName: '' });
    }
    if (errors.lastName !== '' && !lastNameHasChanged) {
      setErrors({ ...errors, lastName: '' });
    }
    return firstNameHasChanged || lastNameHasChanged;
  };

  const handleCancelOnClick = useCallback(() => {
    setFirstName(userFirstName);
    setLastName(userLastName);
  }, [userFirstName, userLastName, setFirstName, setLastName]);

  const handleSaveOnClick = useCallback(() => {
    const firstNameEmpty = firstName === '' ? content.nameError : '';
    const lastNameEmpty = lastName === '' ? content.nameError : '';

    if (firstNameEmpty || lastNameEmpty) {
      setErrors({ firstName: firstNameEmpty, lastName: lastNameEmpty });
    } else {
      setErrors({ firstName: '', lastName: '' });
      saveUser({ firstName, lastName });
    }
  }, [firstName, lastName]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        padding: '16px',
      }}
    >
      <TitleText>{content.title}</TitleText>
      <StyledDivider />
      <Grid container spacing={2} sx={{ paddingTop: '20px' }} alignItems="center">
        {primaryEmail && (
          <>
            <Grid item xs={12} sm={6}>
              <FormInput
                shrink
                disabled
                label={content.emailLabel}
                value={primaryEmail}
                endAdornment={
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BannerWrapper>
                <EmailBanner>
                  <EmailBannerText variant="caption">{content.emailBannerText}</EmailBannerText>
                </EmailBanner>
              </BannerWrapper>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={!primaryEmail ? 12 : 6}>
          <FormInput
            shrink
            required
            error={errors.firstName}
            label={content.firstNameLabel}
            value={firstName}
            onChange={handleFirstNameChange}
          />
        </Grid>
        <Grid item xs={12} sm={!primaryEmail ? 12 : 6}>
          <FormInput
            shrink
            required
            error={errors.lastName}
            label={content.lastNameLabel}
            value={lastName}
            onChange={handleLastNameChange}
          />
        </Grid>
      </Grid>
      {getNameHasChanged() ? (
        <UpdateChangesContainer>
          <CancelCTA variant="outlined" onClick={handleCancelOnClick} disabled={isSavingUser}>
            {content.cancelCTAText}
          </CancelCTA>
          <UpdateCTA variant="contained" onClick={handleSaveOnClick} loading={isSavingUser}>
            {content.updateCTAText}
          </UpdateCTA>
        </UpdateChangesContainer>
      ) : (
        <>
          {/* TODO: Implement reset password for AuthO clients */}
          {/* <StyledDivider />
          <PasswordContainer>
            <PasswordLabel>{content.passwordLabel}</PasswordLabel>
            <ResetPasswordLink>{content.resetPasswordText}</ResetPasswordLink>
          </PasswordContainer> */}
        </>
      )}
    </Card>
  );
};

export default AccountDetails;
