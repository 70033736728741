import React, { useCallback, useState } from 'react';

import { Grid, Skeleton } from '@mui/material';
import { differenceInHours } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import config from '../../config.json';
import { Appointment } from '../../types/Appointment';
import AppointmentCard from '../AppointmentCard/AppointmentCard';
import CancelAppointment from '../Cancellation/CancelAppointment';
import CancellationPolicy from '../Cancellation/CancellationPolicy';
import Modal from '../Modal/Modal';

import {
  BodyText,
  BookingCard,
  InlineBodyText,
  ModalText,
  StyledDivider,
  CancellationContainer,
  TitleText,
  BrandImage,
  TextContainer,
  HeadingText,
  SupportCTA,
  CancellationModalText,
} from './CurrentBookings.styles';

const content = {
  title: 'My Bookings',
  empty: {
    image: {
      src: '/elementor-placeholder-image.png',
      alt: 'placeholder image',
    },
    headingText: 'No Upcoming Booking',
    bodyText: 'When you book an appointment, the details of your next one will be shown here.',
    viewSupportCTA: {
      text: 'View support',
      url: '/providers',
    },
  },
  cancellation: {
    ctaText: 'Cancel appointment',
    disclaimerStart: `You can't cancel this appointment as it is already within ${config.cancellationWindow} hours of your booked time.`,
    disclaimerMiddle: ' Click here ',
    disclaimerEnd: 'for more options.',
  },
};

interface CurrentBookingsProps {
  appointments?: Array<Appointment>;
  isLoading: boolean;
}

const CurrentBookings = ({ appointments, isLoading }: CurrentBookingsProps) => {
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>();
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [isOpenCancelPolicy, setIsOpenCancelPolicy] = useState(false);
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    navigate(content.empty.viewSupportCTA.url);
  }, [navigate]);

  const openCancelModal = useCallback(
    (appointment: Appointment) => {
      setIsOpenCancel(true);
      setSelectedAppointment(appointment);
    },
    [setIsOpenCancel, setSelectedAppointment]
  );

  const closeCancelModal = useCallback(() => {
    setIsOpenCancel(false);
    setSelectedAppointment(null);
  }, [setIsOpenCancel, setSelectedAppointment]);

  const openCancelPolicyModal = useCallback(() => {
    setIsOpenCancelPolicy(true);
  }, [setIsOpenCancelPolicy]);

  const closeCancelPolicyModal = useCallback(() => {
    setIsOpenCancelPolicy(false);
  }, [setIsOpenCancelPolicy]);

  // TODO: Add cleaner loading state
  if (isLoading) {
    return (
      <>
        <TitleText>{content.title}</TitleText>
        <Skeleton width={'100%'} height={590} sx={{ marginTop: '-88px' }}></Skeleton>
      </>
    );
  }

  if (!appointments || appointments.length === 0) {
    return (
      <>
        <TitleText>{content.title}</TitleText>
        <BookingCard>
          <BrandImage {...content.empty.image} width={138} height={92} />
          <TextContainer>
            <HeadingText>{content.empty.headingText}</HeadingText>
            <BodyText>{content.empty.bodyText}</BodyText>
          </TextContainer>
          <SupportCTA onClick={handleOnClick} variant="contained">
            {content.empty.viewSupportCTA.text}
          </SupportCTA>
        </BookingCard>
      </>
    );
  }

  return (
    <>
      <TitleText>{content.title}</TitleText>
      <Grid container sx={{ maxHeight: '856px', overflowY: 'auto' }}>
        {appointments.map((appointment) => {
          const { providerId, appointmentStart } = appointment;

          const date = new Date(appointmentStart);
          const canCancel = differenceInHours(date, new Date()) > config.cancellationWindow;

          return (
            <Grid key={`${providerId}-${appointmentStart}`} item xs={12}>
              <BookingCard>
                <AppointmentCard appointment={appointment} />
                <StyledDivider />
                <CancellationContainer>
                  {canCancel ? (
                    <CancellationModalText onClick={() => openCancelModal(appointment)}>
                      {content.cancellation.ctaText}
                    </CancellationModalText>
                  ) : (
                    <InlineBodyText>
                      {content.cancellation.disclaimerStart}
                      <ModalText onClick={openCancelPolicyModal}>
                        {content.cancellation.disclaimerMiddle}
                      </ModalText>
                      {content.cancellation.disclaimerEnd}
                    </InlineBodyText>
                  )}
                </CancellationContainer>
              </BookingCard>
            </Grid>
          );
        })}
      </Grid>
      <Modal isOpen={isOpenCancelPolicy} onClose={closeCancelPolicyModal}>
        <CancellationPolicy onClose={closeCancelPolicyModal} />
      </Modal>
      <Modal isOpen={isOpenCancel} onClose={closeCancelModal}>
        {selectedAppointment && (
          <CancelAppointment appointment={selectedAppointment} onClose={closeCancelModal} />
        )}
      </Modal>
    </>
  );
};

export default CurrentBookings;
