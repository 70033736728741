import { utcToZonedTime } from 'date-fns-tz';

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MONTH_ACRONYMS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const FORMAT = (date: Date) => {
  return (
    DAYS[date.getDay()] +
    ', ' +
    date.getDate() +
    ' ' +
    MONTH_ACRONYMS[date.getMonth()] +
    ' ' +
    date.getFullYear()
  );
};

export const useTimeZone = (dateUTC: string) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const localDate = utcToZonedTime(dateUTC, timeZone);

  return {
    localDate,
    timeZone,
  };
};
