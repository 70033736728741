import React, { ChangeEvent, ReactNode } from 'react';

import {
  StyledFormControl,
  StyledFormHelperText,
  StyledInputBase,
  StyledInputLabel,
} from './FormInput.styles';

interface InputProps {
  multiline?: boolean;
  rows?: boolean;
  defaultValue?: string;
  required?: boolean;
  shrink?: boolean;
  value?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  type?: string;
  endAdornment?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const FormInput = (props: InputProps) => {
  const isError = props.error ? props.error !== '' : false;
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <StyledFormControl error={isError} required={props.required} ref={ref}>
      <StyledInputLabel shrink={props.shrink}>{props.label}</StyledInputLabel>
      <StyledInputBase
        multiline={props.multiline}
        rows={`${props.rows || (props.multiline ? 4 : 0)}`}
        defaultValue={props.defaultValue}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        disabled={props.disabled}
        type={props.type}
        endAdornment={props.endAdornment}
      />
      {isError && <StyledFormHelperText>{props.error}</StyledFormHelperText>}
    </StyledFormControl>
  );
};

export default FormInput;
