/* eslint-disable @typescript-eslint/no-var-requires */

import React from 'react';

import { SpecialitiesChip } from '../Provider/Provider.styles';

import { DomainIcons } from './DomainIcons';

interface DomainChipProps {
  text: string;
}

const DomainChip: React.FC<DomainChipProps> = ({ text }) => {
  const Icon = DomainIcons[text] || null;

  return <SpecialitiesChip variant="outlined" label={text} icon={Icon ? <Icon /> : <></>} />;
};

export default DomainChip;
