import styled from '@emotion/styled';
import { Button, Container, Divider, Typography } from '@mui/material';

export const TitleText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 24px;
`;

export const SessionsContainer = styled(Container)`
  padding: 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
`;

export const SessionsTitleText = styled(Typography)`
  margin-top: 64px;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
`;

export const BodyText = styled(Typography)`
  margin-top: 36px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
`;

export const RequestMoreButton = styled(Button)`
  margin-top: 28px;
  padding: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  background: ${({ theme }) => theme.palette.primary.main};
  text-transform: none;
`;
