import React from 'react';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MenuIcon from '@mui/icons-material/Menu';
import { ButtonBase, Link, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import { Link as RouterLink } from 'react-router-dom';

import config from '../../config.json';

import { DrawerIconContainer, DrawerLinkText, LinkText } from './Header.styles';

const drawerWidth = 240;
const navItems = config.navLinks;
const iconMaxHeight = config.logo.maxHeight;

const Header = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const returnAppUrl = process.env.REACT_APP_RETURN_APP_URL;
  const returnAppName = process.env.REACT_APP_RETURN_APP_NAME;

  const shouldShowButton = returnAppUrl && returnAppName;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        component="header"
        sx={{ backgroundColor: (t) => t.palette.primary.main, boxShadow: 'none' }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row">
            <ButtonBase>
              <RouterLink to="/">
                <img src={config.logo.light} style={{ maxHeight: iconMaxHeight }} />
              </RouterLink>
            </ButtonBase>
            <ButtonBase sx={{ marginLeft: '24px' }}>
              <Link
                href={`tel:${config.contactNumber}`}
                sx={{
                  color: (t) => t.palette.primary.contrastText,
                  fontSize: '16px',
                  lineHeight: '24px',
                  marginRight: '40px',
                }}
              >
                {config.contactNumber}
              </Link>
            </ButtonBase>
            {shouldShowButton && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => (window.location.href = returnAppUrl)}
                sx={{
                  color: 'white',
                  display: { xs: 'none', sm: 'none', md: 'inline-flex', lg: 'inline-flex' },
                }}
              >
                <KeyboardBackspaceIcon sx={{ marginRight: '8px' }} />
                Back to {returnAppName}
              </Button>
            )}
          </Stack>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            {navItems.map((item) => (
              <LinkText key={item.name} to={item.path}>
                {item.name}
              </LinkText>
            ))}
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: {
                xs: 'inline-flex',
                sm: 'inline-flex',
                md: 'none',
                lg: 'none',
                xl: 'none',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <Box onClick={handleDrawerToggle}>
            <ButtonBase>
              <DrawerIconContainer>
                <img src={config.logo.dark} style={{ maxHeight: iconMaxHeight }} />
              </DrawerIconContainer>
            </ButtonBase>

            <List>
              {navItems.map((item) => (
                <ListItem key={item.name} disablePadding>
                  <DrawerLinkText to={item.path}>{item.name}</DrawerLinkText>
                </ListItem>
              ))}
            </List>
            {shouldShowButton && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%', // Ensure the Box takes the full width
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    color: 'white',
                    maxWidth: '180px',
                    mt: 2, // Adds margin top for spacing, adjust as needed
                  }}
                  onClick={() => (window.location.href = returnAppUrl)}
                >
                  <KeyboardBackspaceIcon />
                  Back to {returnAppName}
                </Button>
              </Box>
            )}
          </Box>
        </Drawer>
      </Box>
      <Toolbar />
    </Box>
  );
};

export default Header;
