import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';

import { useAppointments, useUser } from '../../api';
import config from '../../config.json';
import { Appointment } from '../../types/Appointment';
import { useTimeZone } from '../../utils/dates';
import { validateEmail } from '../../utils/validation';
import AppointmentCard from '../AppointmentCard/AppointmentCard';
import FormInput from '../FormInput/FormInput';

import {
  BodyText,
  BoldBodyText,
  CTAContainer,
  ConfirmationContainer,
  FootnoteText,
  InlineBodyText,
  StyledContainer,
  StyledDivider,
  SubmitCTA,
  TitleText,
} from './BookingConfirmation.styles';

const content = {
  title: 'Confirm your booking with',
  body1: 'Please note all appointments are approximately ',
  durationText: '1 hour. ',
  body2: 'If you are meeting in person, please ',
  timeBufferText: 'arrive 15 minutes before ',
  body3: 'the start time of your appointment.',
  phoneLabel: 'Phone number',
  phoneNumberPlaceholder: 'Contact phone number (required)',
  phoneNumberError: 'Please enter a valid phone number',
  emailLabel: 'Email address',
  emailPlaceholder: 'Email address (required)',
  emailError: 'Please enter a valid email',
  firstNameLabel: 'First name',
  firstNamePlaceholder: 'First name (required)',
  firstNameError: 'Please enter your first name',
  lastNameLabel: 'Last name',
  lastNamePlaceholder: 'Last name (required)',
  lastNameError: 'Please enter your last name',
  notesLabel: 'Notes',
  notesPlaceholder: 'Notes for {{firstName}} to read before your appointment (Optional)',
  ctaText: 'Book appointment',
  footnote: `You can cancel an appointment up to ${
    config.cancellationWindow
  } hours before it's due${config.sessions.hideSessions ? '' : ' and receive a full refund'}.`,
  successTitle: 'Your Booking was Successful',
  successBody: 'A booking confirmation has been sent to:',
  sessionBalance: 'Your updated balance:',
  sessionsText: 'sessions',
  noSessions: 'No available sessions. Request more sessions ',
  requestLinkText: 'here',
  doneCTAText: 'Done',
};

interface BookingConfirmationProps {
  appointment: Appointment;
  userPhoneNumber: string;
  onClose: () => void;
}

const BookingConfirmation = ({
  appointment,
  userPhoneNumber,
  onClose,
}: BookingConfirmationProps) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [availableSessions, setAvailableSessions] = useState(0);
  const [notes, setNotes] = useState('');
  const [success, setSuccess] = useState(false);

  const { createBooking, isCreatingBooking } = useAppointments();
  const { user, saveUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    if (user) {
      const {
        primaryEmail,
        secondaryEmail,
        firstName: userFirstName,
        lastName: userLastName,
        availableTokens,
      } = user;
      if (secondaryEmail) {
        setEmail(secondaryEmail);
      } else if (primaryEmail) {
        setEmail(primaryEmail);
      }
      if (userFirstName) {
        setFirstName(userFirstName);
      }
      if (userLastName) {
        setLastName(userLastName);
      }
      if (availableTokens) {
        setAvailableSessions(availableTokens);
      }
    }
  }, [user, setEmail, setFirstName, setLastName, setAvailableSessions]);

  const handlePhoneNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/\D/g, '');

      if (value === '') {
        setPhoneNumberError(content.phoneNumberError);
      } else if (phoneNumberError !== '') {
        setPhoneNumberError('');
      }
      setPhoneNumber(value);
    },
    [setPhoneNumber, phoneNumberError, setPhoneNumberError]
  );

  const handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value === '') {
        setEmailError('');
      } else if (validateEmail(value)) {
        setEmailError('');
      }
      setEmail(value);
    },
    [setEmail]
  );

  const handleNotesChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setNotes(value);
    },
    [setNotes]
  );

  const handleFirstNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value === '') {
        setFirstNameError('');
      } else if (firstNameError !== '') {
        setFirstNameError('');
      }
      setFirstName(value);
    },
    [firstNameError, setFirstNameError, setFirstName]
  );

  const handleLastNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value === '') {
        setLastNameError('');
      } else if (lastNameError !== '') {
        setLastNameError('');
      }
      setLastName(value);
    },
    [lastNameError, setLastNameError, setLastName]
  );

  const handleOnSubmit = useCallback(async () => {
    const isValidEmail = email !== '' && validateEmail(email);
    if (phoneNumber === '') {
      setPhoneNumberError(content.phoneNumberError);
    }
    if (!isValidEmail) {
      setEmailError(content.emailError);
    }
    if (firstName === '') {
      setFirstNameError(content.firstNameError);
    }
    if (lastName === '') {
      setLastNameError(content.lastNameError);
    }

    if (phoneNumber !== '' && isValidEmail && firstName !== '' && lastName !== '' && !!user) {
      const { localDate, timeZone } = useTimeZone(appointment.appointmentStart);
      if (!user.firstName && !user.lastName) {
        saveUser({ firstName, lastName });
      }
      if (!config.sessions.hideSessions && availableSessions < unitsPerSession) {
        const action = () => (
          <BodyText>
            {content.noSessions}
            <RouterLink to="/" style={{ color: theme.palette.primary.contrastText }}>
              {content.requestLinkText}
            </RouterLink>
          </BodyText>
        );
        enqueueSnackbar('', {
          action,
          variant: 'error',
        });
      } else {
        await createBooking({
          firstName,
          lastName,
          email: email,
          datetime: localDate,
          timeZone,
          providerId: appointment.providerId,
          appointmentType: appointment.appointmentType.trim(),
          phone: phoneNumber,
          organisaion: 'Org',
          appointmentNote: notes,
        });
        setSuccess(true);
      }
    }
  }, [firstName, lastName, email, phoneNumber, notes]);

  const handleDoneOnClick = useCallback(() => {
    setTimeout(() => {
      onClose();
    }, 1000);
  }, [onClose]);

  const { providerFirstName, providerLastName, unitsPerSession } = appointment;

  return success ? (
    <StyledContainer>
      <TitleText>{`${content.title} ${providerFirstName} ${providerLastName}`}</TitleText>
      <br />
      <BodyText>{content.successBody}</BodyText>
      <BoldBodyText>{email}</BoldBodyText>
      <br />
      {!config.sessions.hideSessions && (
        <>
          <br />
          <BodyText>{content.sessionBalance}</BodyText>
          <BoldBodyText>
            {`${user && user.availableTokens - unitsPerSession} ${content.sessionsText}`}
          </BoldBodyText>
          <br />
          <br />
        </>
      )}
      <CTAContainer>
        <SubmitCTA variant="contained" onClick={handleDoneOnClick}>
          {content.doneCTAText}
        </SubmitCTA>
      </CTAContainer>
    </StyledContainer>
  ) : (
    <ConfirmationContainer>
      <TitleText>{`${content.title} ${providerFirstName} ${providerLastName}`}</TitleText>
      <InlineBodyText>
        {content.body1}
        <BoldBodyText>{content.durationText}</BoldBodyText>
        {content.body2}
        <BoldBodyText>{content.timeBufferText}</BoldBodyText>
        {content.body3}
      </InlineBodyText>
      <StyledDivider />
      <AppointmentCard appointment={appointment} hideAvatar />
      <StyledDivider />
      {user && !user.firstName && (
        <FormInput
          shrink
          label={content.firstNameLabel}
          error={firstNameError}
          placeholder={content.firstNamePlaceholder}
          onChange={handleFirstNameChange}
          value={firstName}
          disabled={isCreatingBooking}
        />
      )}
      {user && !user.lastName && (
        <FormInput
          shrink
          label={content.lastNameLabel}
          error={lastNameError}
          placeholder={content.lastNamePlaceholder}
          onChange={handleLastNameChange}
          value={lastName}
          disabled={isCreatingBooking}
        />
      )}
      <FormInput
        shrink
        label={content.phoneLabel}
        error={phoneNumberError}
        placeholder={content.phoneNumberPlaceholder}
        onChange={handlePhoneNumberChange}
        value={phoneNumber}
        disabled={isCreatingBooking}
      />
      <FormInput
        shrink
        label={content.emailLabel}
        error={emailError}
        placeholder={content.emailPlaceholder}
        onChange={handleEmailChange}
        value={email}
        disabled={isCreatingBooking}
      />
      <FormInput
        shrink
        label={content.notesLabel}
        multiline
        placeholder={content.notesPlaceholder.replace('{{firstName}}', providerFirstName)}
        onChange={handleNotesChange}
        value={notes}
        disabled={isCreatingBooking}
      />
      <CTAContainer>
        <SubmitCTA variant="contained" onClick={handleOnSubmit} loading={isCreatingBooking}>
          {content.ctaText}
        </SubmitCTA>
      </CTAContainer>
      <FootnoteText>{content.footnote}</FootnoteText>
    </ConfirmationContainer>
  );
};

export default BookingConfirmation;
