import axios from 'axios';
import { addHours, isBefore } from 'date-fns';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { Appointment } from '../types/Appointment';
import { Booking } from '../types/Booking';

import { fetcher } from './fetcher';

const sanitizeAppointments = (data?: Appointment[]) => {
  if (!data) {
    return { currentAppointments: [], pastAppointments: [] };
  }

  // TODO: Remove when API retuns appointmentStart and appointmentEnd
  const appointments = data?.map((appointment) => {
    const { appointmentDate } = appointment;
    const startDate = new Date(appointmentDate);
    const endDate = addHours(startDate, 1).toISOString();

    return {
      ...appointment,
      appointmentStart: appointmentDate,
      appointmentEnd: endDate,
    };
  });

  const now = new Date();
  const currentAppointments = appointments
    ?.filter((appointment) => new Date(appointment.appointmentDate) >= now)
    .sort((appt1, appt2) =>
      isBefore(new Date(appt1.appointmentDate), new Date(appt2.appointmentDate)) ? -1 : 1
    );
  const pastAppointments = appointments
    ?.filter((appointment) => new Date(appointment.appointmentDate) <= now)
    .sort((appt1, appt2) =>
      isBefore(new Date(appt1.appointmentDate), new Date(appt2.appointmentDate)) ? 1 : -1
    );

  return {
    currentAppointments,
    pastAppointments,
  };
};

export const useAppointments = () => {
  const { data, isLoading, mutate } = useSWR<Appointment[]>('/User/appointments', fetcher);

  const { trigger: createBooking, isMutating: isCreatingBooking } = useSWRMutation(
    '/Appointment/booking',
    (url: string, { arg }: { arg: Booking }) => axios.post(url, arg).then(() => mutate())
  );

  const { trigger: cancelBooking, isMutating: isCancellingBooking } = useSWRMutation(
    '/Appointment/cancel',
    (url: string, { arg }: { arg: number }) => axios.post(url, { id: arg }).then(() => mutate())
  );

  // TODO: Update when API returns appointmentStart and appointmentEnd
  const { currentAppointments, pastAppointments } = sanitizeAppointments(data);

  return {
    currentAppointments,
    pastAppointments,
    isLoading,
    createBooking,
    isCreatingBooking,
    cancelBooking,
    isCancellingBooking,
  };
};
