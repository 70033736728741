import React, { useCallback, useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';
import { Chip, Container, Grid,  Skeleton, Stack, Typography } from '@mui/material';
import { isBefore } from 'date-fns';
import { generatePath, useNavigate } from 'react-router-dom';

import { useFilters, useProviders } from '../../api';
import { useFeatureFlags, FeatureFlag } from '../../api/featureFlagsProvider';
import { DomainIcons } from '../../components/Domains/DomainIcons';
import AppointmentTypeFilter from '../../components/Filters/AppointmentTypeFilter';
import DomainFilter from '../../components/Filters/DomainFilter';
import LocationFilter from '../../components/Filters/LocationFilter';
import SpecialtyFilter from '../../components/Filters/SpecialtyFilter';
import Modal from '../../components/Modal/Modal';
import Provider from '../../components/Provider/Provider';
import Splash from '../../components/Splash/Splash';
import config from '../../config.json';
import SliderIcon from '../../icons/SliderIcon';
import { Provider as ProviderType } from '../../types/Provider';

import {
  ProviderContainer,
  BodyContainer,
  FilterContainer,
  Subtitle,
  Title,
  ChipContainer,
  MobileFilterContainer,
  FilterText,
  BodyText,
} from './Providers.styles';

const SPECIALTY_ID = 'specialty';
const APPOINTMENT_TYPE_ID = 'appointmentType';
const LOCATION_ID = 'location';
const DOMAIN_ID = 'domain';

const content = {
  mobileFilterText: 'Search & Filter Providers',
  resultsText: 'Results',
};

const Providers = () => {
  const [providersList, setProvidersList] = useState<Array<ProviderType>>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [specialtyFilter, setSpecialtyFilter] = useState<Array<string>>([]);
  const [appointmentTypeFilter, setAppointmentTypeFilter] = useState<Array<string>>([]);
  const [locationFilter, setLocationFilter] = useState<Array<string>>([]);
  const [domainFilter, setDomainFilter] = useState<Array<string>>([]);

  const { providers } = useProviders();
  const navigate = useNavigate();
  const theme = useTheme();
  const { cities, specialities, isLoading } = useFilters();
  const featureFlags = useFeatureFlags();
  const domainsofwellbeingEnabled = featureFlags?.some(
    (flag: FeatureFlag) => flag.name === 'domainsofwellbeing' && flag.enabled
  );
  const openModal = useCallback(() => {
    setIsOpenModal(true);
  }, [setIsOpenModal]);

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  const filterSpeciality = useCallback(
    (option: Array<string>) => {
      setSpecialtyFilter(option);
    },
    [setSpecialtyFilter]
  );

  const filterAppointmentType = useCallback(
    (option: Array<string>) => {
      setAppointmentTypeFilter(option);
    },
    [setAppointmentTypeFilter]
  );
  const filterDomain = useCallback(
    (option: Array<string>) => {
      setDomainFilter(option);
    },
    [setDomainFilter]
  );

  const filterLocation = useCallback(
    (option: Array<string>) => {
      setLocationFilter(option);
    },
    [setLocationFilter]
  );

  const handleOnSelect = useCallback(
    (providerId: string) => {
      const path = generatePath('/providers/:id', { id: providerId });
      navigate(path);
    },
    [navigate]
  );

  const deleteChip = (filter: Array<string>, chip: string) =>
    filter.filter((option) => option !== chip);

  const handleSpecialtyChipDelete = useCallback(
    (chip: string) => {
      const newFilter = specialtyFilter && deleteChip(specialtyFilter, chip);
      setSpecialtyFilter(newFilter);
    },
    [specialtyFilter, setSpecialtyFilter]
  );

  const handleAppointmentTypeChipDelete = useCallback(
    (chip: string) => {
      const newFilter = appointmentTypeFilter && deleteChip(appointmentTypeFilter, chip);
      setAppointmentTypeFilter(newFilter);
    },
    [appointmentTypeFilter, setAppointmentTypeFilter]
  );

  const handleLocationChipDelete = useCallback(
    (chip: string) => {
      const newFilter = locationFilter && deleteChip(locationFilter, chip);
      setLocationFilter(newFilter);
    },
    [locationFilter, setLocationFilter]
  );

  const handleDomainChipDelete = useCallback(
    (chip: string) => {
      const newFilter = domainFilter && deleteChip(domainFilter, chip);
      setDomainFilter(newFilter);
    },
    [domainFilter, setDomainFilter]
  );

  useEffect(() => {
    if (providers?.length && !providersList) {
      const sortedProviders = [...providers].sort((a, b) => {
        const aTime = a.nextAvailableTime;
        const bTime = b.nextAvailableTime;
        if (!aTime) {
          return 1;
        } else if (!bTime) {
          return -1;
        } else {
          const isNextAvailable = isBefore(new Date(bTime), new Date(aTime));
          return isNextAvailable ? 1 : -1;
        }
      });
      setProvidersList(sortedProviders);
    }
  }, [providers]);

  useEffect(() => {
    if (providers) {
      const emptySpecialty = specialtyFilter.length === 0;
      const emptyAppointmentType = appointmentTypeFilter.length === 0;
      const emptyLocation = locationFilter.length === 0;
      const emptyDomain = domainFilter.length === 0;

      const newFilters = providers.filter((item) => {
        const hasSpecialty = emptySpecialty
          ? emptySpecialty
          : specialtyFilter.filter((specialty) => item.specialties.includes(specialty)).length > 0;
        const hasAppointmentType = emptyAppointmentType
          ? emptyAppointmentType
          : appointmentTypeFilter.filter((appointmentType) =>
              item.bookingTypesAvailable.includes(appointmentType)
            ).length > 0;
        const hasDomain = emptyDomain
          ? emptyDomain
          : domainFilter.filter((domain) => item.domains.includes(domain)).length > 0;
        const hasLocation = emptyLocation
          ? emptyLocation
          : locationFilter.filter((location) => item.city.includes(location)).length > 0;

        return hasSpecialty && hasAppointmentType && hasLocation && hasDomain;
      });

      const sortedFilters = [...newFilters].sort((a, b) => {
        const aTime = a.nextAvailableTime;
        const bTime = b.nextAvailableTime;
        if (!aTime) {
          return 1;
        } else if (!bTime) {
          return -1;
        } else {
          const isNextAvailable = isBefore(new Date(bTime), new Date(aTime));
          return isNextAvailable ? 1 : -1;
        }
      });

      setProvidersList(sortedFilters);
    }
  }, [specialtyFilter, appointmentTypeFilter, locationFilter, domainFilter]);

  return (
    <ProviderContainer>
      <Splash>
        <Container sx={{ paddingTop: '2%' }}>
          <Title>{config.providers.title}</Title>
          <Subtitle>{config.providers.description}</Subtitle>
        </Container>
      </Splash>
      {isLoading ? (
        <Skeleton
          width={'100%'}
          height={80}
          sx={{ marginTop: '-18px', display: { md: 'none' } }}
          animation="wave"
        />
      ) : (
        <MobileFilterContainer onClick={openModal}>
          <SliderIcon
            stroke={theme.palette.primary.contrastText}
            fill={theme.palette.primary.dark}
          />
          <FilterText>{content.mobileFilterText}</FilterText>
        </MobileFilterContainer>
      )}
      <Container>
        <BodyContainer>
          {isLoading ? (
            <Skeleton
              width={'100%'}
              height={208}
              sx={{ marginTop: '-88px', display: { xs: 'none', md: 'block' } }}
            />
          ) : (
            <>
              <FilterContainer>
                {domainsofwellbeingEnabled && (
                  <DomainFilter onChange={filterDomain} values={domainFilter} />
                )}
                {!config.providers.hideSpecialties && (
                  <SpecialtyFilter
                    onChange={filterSpeciality}
                    values={specialtyFilter}
                    options={specialities}
                  />
                )}
                <AppointmentTypeFilter
                  onChange={filterAppointmentType}
                  values={appointmentTypeFilter}
                />
                <LocationFilter
                  onChange={filterLocation}
                  values={locationFilter}
                  options={cities}
                />
              </FilterContainer>
              <Modal isOpen={isOpenModal} onClose={closeModal} fullHeight>
                {!config.providers.hideSpecialties && (
                  <SpecialtyFilter
                    onChange={filterSpeciality}
                    values={specialtyFilter}
                    options={specialities}
                  />
                )}
                <AppointmentTypeFilter
                  onChange={filterAppointmentType}
                  values={appointmentTypeFilter}
                />
                <LocationFilter
                  onChange={filterLocation}
                  values={locationFilter}
                  options={cities}
                />
              </Modal>
            </>
          )}
          <Grid container sx={{ marginTop: '24px' }}>
            <Grid item xs={12} md={8}>
              <ChipContainer>
                {domainFilter &&
                  domainFilter.map((option) => {
                    const IconComponent = DomainIcons[option as keyof typeof DomainIcons];
                    return (
                      <Chip
                        key={`${DOMAIN_ID}-${option}`}
                        label={option}
                        icon={IconComponent ? <IconComponent /> : undefined}
                        onDelete={() => handleDomainChipDelete(option)}
                        sx={{ marginRight: '4px' }}
                      />
                    );
                  })}
                {specialtyFilter &&
                  specialtyFilter.map((option) => (
                    <Chip
                      key={`${SPECIALTY_ID}-${option}`}
                      label={option}
                      onDelete={() => handleSpecialtyChipDelete(option)}
                      sx={{ marginRight: '4px' }}
                    />
                  ))}
                {appointmentTypeFilter &&
                  appointmentTypeFilter.map((option) => (
                    <Chip
                      key={`${APPOINTMENT_TYPE_ID}-${option}`}
                      label={option}
                      onDelete={() => handleAppointmentTypeChipDelete(option)}
                      sx={{ marginRight: '4px' }}
                    />
                  ))}
                {locationFilter &&
                  locationFilter.map((option) => (
                    <Chip
                      key={`${LOCATION_ID}-${option}`}
                      label={option}
                      onDelete={() => handleLocationChipDelete(option)}
                      sx={{ marginRight: '4px' }}
                    />
                  ))}
              </ChipContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container alignItems="center" justifyContent="flex-end">
                {providersList?.length && (
                  <>
                    <Grid item textAlign="right">
                      <BodyText>{`${providersList?.length} ${content.resultsText}`}</BodyText>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </BodyContainer>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="flex-start"
          sx={{ paddingTop: '40px', paddingBottom: '40px' }}
        >
          {providersList?.map((provider) => {
            return (
              <Grid key={provider.providerId} item lg={4} md={6} sm={12}>
                <Provider data={provider} onSelect={handleOnSelect} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
      {config.providers.showSupportText && (
        <Container maxWidth="sm">
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            textAlign="center"
            justifyContent="center"
            pt={2}
            pb={8}
          >
              <Typography>
              {(config.providers && config.providers.footer) || ''}
            </Typography>

          
          </Stack>
        </Container>
      )}
    </ProviderContainer>
  );
};

export default Providers;
