import { IconProps } from './Icons.types';

const SliderIcon = ({
  fill = '#2C397F',
  width = '21',
  height = '20',
  stroke = '#FFFFFF',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.4375 3.7439H19.3125"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="5.60832" cy="3.74382" r="2.22917" fill={fill} stroke={stroke} strokeWidth="1.5" />
    <path
      d="M1.4375 10H19.3125"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="15.1416" cy="9.99992" r="2.22917" fill={fill} stroke={stroke} strokeWidth="1.5" />
    <path
      d="M1.4375 16.2566H19.3125"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="5.60832" cy="16.2565" r="2.22917" fill={fill} stroke={stroke} strokeWidth="1.5" />
  </svg>
);

export default SliderIcon;
