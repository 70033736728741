import React, { useCallback, useState } from 'react';

import { Avatar, Grid, Skeleton } from '@mui/material';
import { format } from 'date-fns';

import { Appointment } from '../../types/Appointment';
import { DAYS, MONTH_ACRONYMS } from '../../utils/dates';
import AppointmentDetails from '../AppointmentDetails/AppointmentDetails';
import Modal from '../Modal/Modal';

import {
  BioContainer,
  BioText,
  BodyText,
  BoldText,
  BookingCard,
  BookingGrid,
  BrandImage,
  DateTimeText,
  DetailContainer,
  EmptyBookingCard,
  HeadingText,
  InfoContainer,
  LinkText,
  LoadedAppointmentsCard,
  NameText,
  StyledDivider,
  TextContainer,
  TitleText,
} from './PastBookings.styles';

interface PastBookingsProps {
  appointments?: Array<Appointment>;
  totalAppointments?: number;
  isLoading: boolean;
}

const content = {
  title: 'Past Bookings',
  image: {
    src: '/elementor-placeholder-image.png',
    alt: 'placeholder image',
  },
  headingText: 'No Past Bookings',
  bodyText:
    'After you attend a support appointment or workshop, the record of it will be shown here',
  detailsLinkText: 'View details',
  seeMoreText: 'See previous appointments',
  loadedAppointments: 'Loaded {{total}}',
  appointmentText: 'appointment',
  appointmentsText: 'appointments',
};

const PastBookings = ({ appointments, isLoading }: PastBookingsProps) => {
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = useCallback(
    (appointment: Appointment) => {
      setSelectedAppointment(appointment);
      setIsOpenModal(true);
    },
    [setIsOpenModal]
  );

  const closeModal = useCallback(() => {
    setSelectedAppointment(null);
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  // TODO: Add cleaner loading state
  if (isLoading) {
    return (
      <>
        <TitleText>{content.title}</TitleText>
        <Skeleton width={'100%'} height={590} sx={{ marginTop: '-88px' }}></Skeleton>
      </>
    );
  }

  if (!appointments || appointments.length === 0) {
    return (
      <>
        <TitleText>{content.title}</TitleText>
        <EmptyBookingCard>
          <BrandImage {...content.image} width={138} height={92} />
          <TextContainer>
            <HeadingText>{content.headingText}</HeadingText>
            <BodyText>{content.bodyText}</BodyText>
          </TextContainer>
        </EmptyBookingCard>
      </>
    );
  }

  // TODO: Replace with past appointments/total appointments from API
  const loadedAppointments = `
  ${content.loadedAppointments.replace('{{total}}', `${appointments.length}`)} ${
    appointments.length === 1 ? content.appointmentText : content.appointmentsText
  }`;

  return (
    <>
      <TitleText>{content.title}</TitleText>
      <Modal isOpen={isOpenModal} onClose={closeModal}>
        {selectedAppointment && <AppointmentDetails appointment={selectedAppointment} />}
      </Modal>
      <BookingCard>
        <Grid container>
          {appointments.map((appointment) => {
            const {
              providerId,
              providerFirstName,
              providerLastName,
              providerType,
              providerImageUrl,
              appointmentStart,
            } = appointment;

            const initials = `${providerFirstName[0]}${providerLastName[0]}`;
            const date = new Date(appointmentStart);

            return (
              <React.Fragment key={`${providerId}-${appointmentStart}`}>
                <BookingGrid item xs={12}>
                  <Avatar
                    src={providerImageUrl}
                    alt={initials}
                    sx={{ bgcolor: (t) => t.palette.primary.main, width: 60, height: 60 }}
                  >
                    {!providerImageUrl && initials.toUpperCase()}
                  </Avatar>
                  <DetailContainer>
                    <BioContainer>
                      <NameText noWrap>{`${providerFirstName} ${providerLastName}`}</NameText>
                      <BioText noWrap>{`${providerType}`}</BioText>
                    </BioContainer>
                    <InfoContainer>
                      <DateTimeText>{`${format(date, 'h:mm a')}, ${
                        DAYS[date.getDay()]
                      }, ${date.getDate()} ${
                        MONTH_ACRONYMS[date.getMonth()]
                      } ${date.getFullYear()}`}</DateTimeText>
                      <LinkText onClick={() => openModal(appointment)}>
                        {content.detailsLinkText}
                      </LinkText>
                    </InfoContainer>
                  </DetailContainer>
                </BookingGrid>
                <StyledDivider />
              </React.Fragment>
            );
          })}
        </Grid>
      </BookingCard>
      <LoadedAppointmentsCard>
        <BoldText>{loadedAppointments}</BoldText>
        {/* TODO: Implement <LinkText>{content.seeMoreText}</LinkText> */}
      </LoadedAppointmentsCard>
    </>
  );
};

export default PastBookings;
