import styled from '@emotion/styled';
import { FormControl, FormHelperText, InputBase, InputLabel } from '@mui/material';

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  max-width: 461px;
  height: auto;
  margin-top: 32px;
`;

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '&.MuiInputLabel-shrink': {
    fontWeight: 700,
    transform: 'translate(0px, -24px)',
    color: theme.palette.text.primary,
  },
  '&.MuiInputLabel-formControl': {
    fontSize: '14px',
    lineHeight: '20px',
  },
  '&.Mui-focused': {
    fontWeight: 700,
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  '&.MuiInputBase-root': {
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: '5px',
    padding: '10px 12px',
    fontSize: '14px',
    lineHeight: '20px',
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
  },
}));

export const StyledFormHelperText = styled(FormHelperText)`
  margin-left: 0;px
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.palette.error.main};
`;
