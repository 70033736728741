import React from 'react';

import Filter from './Filter';
import { FilterContainer, FilterTitle } from './Filter.styles';

const content = {
  label: 'Specialty area',
  placeholder: 'All Specialties Areas',
};

interface SpecialtyFilterProps {
  onChange: (option: Array<string>) => void;
  values: Array<string>;
  options?: Array<string>;
}

const SpecialtyFilter = ({ onChange, values, options }: SpecialtyFilterProps) => {
  if (!options) {
    return <></>;
  }

  const placeholderText = values.length > 0 ? `${values.length} selected` : content.placeholder;
  return (
    <FilterContainer>
      <FilterTitle>{content.label}</FilterTitle>
      <Filter options={options} label={placeholderText} onChange={onChange} values={values} />
    </FilterContainer>
  );
};

export default SpecialtyFilter;
