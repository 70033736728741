import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Container, Divider, Typography } from '@mui/material';

export const StyledContainer = styled(Container)`
  padding: 0px;
  text-align: center;
`;

export const ConfirmationContainer = styled(Container)`
  @media screen and (min-width: 600px) {
    max-height: 85vh;
  }
`;

export const TitleText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
`;

export const BodyText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.44px;
`;

export const InlineBodyText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const BoldBodyText = styled.b`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 16px;
`;

export const CTAContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SubmitCTA = styled(LoadingButton)`
  margin: auto;
  padding: 16px 56px;
  background: ${({ theme }) => theme.palette.primary.main};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-transform: none;
`;

export const FootnoteText = styled(Typography)`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
`;
