import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Header from './components/Header/Header';
import config from './config.json';
import Dashboard from './pages/Dashboard/Dashboard';
import ProviderDetails from './pages/ProviderDetails/ProviderDetails';
import Providers from './pages/Providers/Providers';
import theme from './theme';
import ScrollToTop from './utils/ScrollToTop';

const withHeader = (Child: JSX.Element) => (
  <>
    <Header />
    {Child}
  </>
);

const App = () => (
  <Router>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{config.productName}</title>
            <meta name="description" content="Ignite White Label project" />
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsID}`}
            />
            <script>
              {`window.dataLayer = window.dataLayer || [];
             function gtag(){dataLayer.push(arguments);}
             gtag('js', new Date());
             gtag('config', '${config.googleAnalyticsID}');`}
            </script>
          </Helmet>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={withHeader(<Dashboard />)} />
            <Route path="/providers" element={withHeader(<Providers />)} />
            <Route path="/providers/:providerId" element={withHeader(<ProviderDetails />)} />
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </Router>
);

export default App;
