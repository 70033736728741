import styled from '@emotion/styled';
import { Button, Card, Chip, Container, Divider, Typography } from '@mui/material';

export const BookingCardDesktop = styled(Card)`
  padding: 24px;
  max-width: 380px;
  @media screen and (max-width: 600px) {
    display: none;
    padding: 0px;
  }
`;

export const BookingCardMobile = styled(Card)`
  display: none;
  @media screen and (max-width: 600px) {
    display: flex;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    padding: 24px 0px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const BookingContainer = styled(Container)`
  padding: 0px !important;
  @media screen and (max-width: 600px) {
    text-align: center;
    margin-bottom: 80px;
  }
`;

export const TitleText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  margin-bottom: 40px;
  @media screen and (max-width: 600px) {
    margin-top: 16px;
  }
`;

export const LabelText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 12px;
`;

export const BoldText = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const DisclaimerText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 16px;
`;

export const BodyText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
`;

export const CTAContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const BookCTA = styled(Button)`
  margin: auto;
  padding: 16px 56px;
  background: ${({ theme }) => theme.palette.primary.main};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-transform: none;
`;

export const TimeChip = styled(Chip)(({ theme }) => ({
  width: '108px',
  padding: '8px 12px',
  background: 'transparent',
  borderRadius: '5px',

  '&.MuiChip-label': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.44px',
  },
  '&.MuiChip-outlined': {
    padding: '7px 11px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.light,
      background: theme.palette.primary.light,
    },
  },
  '&:hover': {
    borderColor: theme.palette.primary.light,
    background: theme.palette.primary.light,
  },
  '@media screen and (max-width: 600px)': {
    padding: '4px 7px',
    '&.MuiChip-outlined': {
      padding: '4px 6px',
      border: '1px solid',
      borderColor: theme.palette.primary.main,
    },
  },
}));
