import { format, isSameDay } from 'date-fns';
import useSWR from 'swr';

import { AvailableDate } from '../types/AvalableDate';
import { Filters, Provider } from '../types/Provider';
import { useTimeZone } from '../utils/dates';

import { fetcher } from './fetcher';

const sanitizeAndGroupDateTimes = (dates?: AvailableDate[]) => {
  if (!dates || dates.length === 0) {
    return [];
  }

  return dates
    .map((date) =>
      date.times.map((time) => {
        const { localDate } = useTimeZone(time);
        return localDate;
      })
    )
    .flat(1)
    .reduce((acc: AvailableDate[], availableTime) => {
      const dateString = availableTime.toISOString();
      // check if date group already exists for available time
      const existingEntry = acc.find((entry) => isSameDay(new Date(entry.date), availableTime));

      if (existingEntry) {
        existingEntry.times.push(dateString);
      } else {
        acc.push({
          date: dateString,
          times: [dateString],
        });
      }

      return acc;
    }, []);
};

export const useProviders = () => {
  const { data } = useSWR<Provider[]>('/Provider', fetcher);

  return {
    providers: data,
  };
};

export const useProvider = (providerId: string) => {
  const { data } = useSWR<Provider>(`/Provider?providerId=${providerId}`, fetcher);

  return {
    provider: data,
  };
};

export const useFilters = () => {
  const { data, isLoading } = useSWR<Filters>('/Provider/filters', fetcher);

  return {
    cities: data?.cities,
    specialities: data?.specialities,
    isLoading,
  };
};

export const useAvailableDates = (providerId: string, startDate: Date) => {
  const { data } = useSWR<AvailableDate[]>(
    `/Provider/available-dates?providerId=${providerId}&startDate=${format(
      startDate,
      'yyyy-MM-dd'
    )}`,
    fetcher
  );

  const availableDates = sanitizeAndGroupDateTimes(data);

  return {
    availableDates,
  };
};
