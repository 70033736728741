import React from 'react';

import { useMediaQuery } from '@mui/material';

import Filter from './Filter';
import { FilterContainer, FilterTitle } from './Filter.styles';
import MobileFilter from './MobileFilter';

const content = {
  label: 'Appointment type',
  placeholder: 'All Appointment Types',
  options: ['In Person', 'Video', 'Phone'],
};

interface AppointmentTypeFilterProps {
  onChange: (option: Array<string>) => void;
  values: Array<string>;
}

const AppointmentTypeFilter = ({ onChange, values }: AppointmentTypeFilterProps) => {
  const isDesktop = useMediaQuery('(min-width:900px)');

  return (
    <FilterContainer>
      <FilterTitle>{content.label}</FilterTitle>
      {isDesktop ? (
        <Filter
          options={content.options}
          label={content.placeholder}
          onChange={onChange}
          values={values}
        />
      ) : (
        <MobileFilter
          options={content.options}
          label={content.placeholder}
          onChange={onChange}
          values={values}
        />
      )}
    </FilterContainer>
  );
};

export default AppointmentTypeFilter;
