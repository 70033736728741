import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Button, Container, Divider, Typography } from '@mui/material';

export const CancellationContainer = styled(Container)`
  text-align: center;
  padding: 0px;
`;

export const TitleText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  @media screen and (max-width: 600px) {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const BodyContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const BodyText = styled(Typography)`
  ƒfont-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const BoldBodyText = styled.b`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const PhoneNumberText = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;

export const CTA = styled(Button)`
  padding: 16px 56px;
  background: ${({ theme }) => theme.palette.primary.main};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-transform: none;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CancelCTA = styled(LoadingButton)`
  padding: 16px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-transform: none;
  @media screen and (max-width: 600px) {
    padding: 16px 0px;
  }
`;
