import React, { ReactNode, useCallback } from 'react';

import Close from '@mui/icons-material/Close';
import { IconButton, Modal as MuiModal } from '@mui/material';

import { ChildContainer, CloseContainer, StyledPaper } from './Modal.styles';

interface ModalProps {
  isOpen: boolean;
  fullHeight?: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal = ({ isOpen, fullHeight, onClose, children }: ModalProps) => {
  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <MuiModal open={isOpen}>
      <StyledPaper sx={{ height: fullHeight ? '100%' : 'auto' }}>
        <CloseContainer onClick={handleOnClose}>
          <IconButton>
            <Close />
          </IconButton>
        </CloseContainer>
        <ChildContainer>{children}</ChildContainer>
      </StyledPaper>
    </MuiModal>
  );
};

export default Modal;
