import styled from '@emotion/styled';
import { Chip, Divider, Typography } from '@mui/material';

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LocationText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const NameText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  @media screen and (min-width: 600px) {
    font-size: 20px;
  }
`;

export const BioText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const LabelText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 8px;
`;

export const SpecialitiesChip = styled(Chip)`
  margin: 5px;
  font-size: 14px;
  margin-left: 0;
  border-radius: 5px;
`;
