import { IconProps } from './Icons.types';

const PhoneIcon = ({
  fill = 'none',
  width = '11',
  height = '17',
  stroke = '#0B112C',
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill}>
    <rect width="9" height="15" x="1" y="1" stroke={stroke} rx="2" />
    <path stroke={stroke} d="M1 3.5h9M1 13.5h9" />
  </svg>
);

export default PhoneIcon;
