import { useCallback, useState } from 'react';

import { Container } from '@mui/material';

import { useAppointments, useUser } from '../../api';
import config from '../../config.json';
import { Appointment } from '../../types/Appointment';
import AppointmentCard from '../AppointmentCard/AppointmentCard';

import {
  BodyContainer,
  BodyText,
  BoldBodyText,
  CancelCTA,
  CancellationContainer,
  CTA,
  StyledDivider,
  TitleText,
} from './Cancellation.styles';

const content = {
  default: {
    title: 'Your Booking with',
    labels: {
      appointmentType: 'Appointment Type',
      dateTime: 'Date & Time',
      location: 'Location',
    },
    ctaText: 'Cancel appointment',
    refundText: 'You will be refunded ',
  },
  success: {
    title: 'Appointment Cancelled',
    body1: 'Your appointment with',
    body2: ' was successfully cancelled.',
    refundText: 'We have refunded you: ',
    balanceText: 'Your updated balance is: ',
    ctaText: 'Done',
  },
  sessionText: 'session',
  sessionsText: 'sessions',
};

interface CancelAppointmentProps {
  appointment: Appointment;
  onClose: () => void;
}

const CancelAppointment = ({ appointment, onClose }: CancelAppointmentProps) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { cancelBooking, isCancellingBooking } = useAppointments();
  const { user } = useUser();

  const handleCancelCtaOnClick = useCallback(async () => {
    if (appointment.bookingId) {
      await cancelBooking(appointment.bookingId);
      setIsSuccess(true);
    }
  }, [setIsSuccess]);

  const handleDoneCtaOnClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const fullname = `${appointment.providerFirstName} ${appointment.providerLastName}`;

  const getSessionText = (amount: number) =>
    amount === 1 ? content.sessionText : content.sessionsText;

  return (
    <CancellationContainer>
      {isSuccess ? (
        <>
          <TitleText>{`${content.success.title}`}</TitleText>
          <BodyContainer>
            <BodyText>{`${content.success.body1} ${fullname} ${content.success.body2}`}</BodyText>
            {!config.sessions.hideSessions && (
              <>
                <br />
                <BodyText>
                  {content.success.refundText}
                  <BoldBodyText>{appointment.unitsPerSession}</BoldBodyText>
                  {` ${getSessionText(appointment.unitsPerSession)}`}
                </BodyText>
                {user && (
                  <BodyText>
                    {content.success.balanceText}
                    <BoldBodyText>
                      {`${user.availableTokens + appointment.unitsPerSession}`}
                    </BoldBodyText>
                    {` ${getSessionText(user.availableTokens + appointment.unitsPerSession)}`}
                  </BodyText>
                )}
              </>
            )}
          </BodyContainer>
          <CTA variant="contained" fullWidth onClick={handleDoneCtaOnClick}>
            {content.success.ctaText}
          </CTA>
        </>
      ) : (
        <>
          <TitleText>{content.default.title}</TitleText>
          <TitleText>{fullname}</TitleText>
          <StyledDivider />
          <Container sx={{ textAlign: 'left', padding: '0 !important' }}>
            <AppointmentCard appointment={appointment} hideAvatar />
          </Container>
          <StyledDivider />
          <BodyContainer>
            <CancelCTA
              variant="contained"
              color="error"
              fullWidth
              onClick={handleCancelCtaOnClick}
              loading={isCancellingBooking}
            >
              {content.default.ctaText}
            </CancelCTA>
          </BodyContainer>
          {!config.sessions.hideSessions && (
            <BodyText>
              {content.default.refundText}
              <BoldBodyText>{appointment.unitsPerSession}</BoldBodyText>
              {` ${getSessionText(appointment.unitsPerSession)}`}
            </BodyText>
          )}
        </>
      )}
    </CancellationContainer>
  );
};

export default CancelAppointment;
