import React, { useCallback, useState } from 'react';

import { Grid, Skeleton } from '@mui/material';

import { useUser } from '../../api';
import config from '../../config.json';
import AccountDetails from '../AccountDetails/AccountDetails';
import Modal from '../Modal/Modal';
import PersonalCommunications from '../PersonalCommunications/PersonalCommunications';
import RequestSessions from '../RequestSessions/RequestSessions';
import Sessions from '../Sessions/Sessions';

import { TitleText } from './MyAccount.styles';

const content = {
  title: 'My Account',
};

const MyAccount = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { user, isLoading, saveUser, isSavingUser } = useUser();

  const openModal = useCallback(() => {
    setIsOpenModal(true);
  }, [setIsOpenModal]);

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  if (isLoading || !user) {
    return <Skeleton height={560} />;
  }

  const { firstName, lastName, availableTokens, primaryEmail, secondaryEmail, phone } = user;

  return (
    <>
      <TitleText>{content.title}</TitleText>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <AccountDetails
            userFirstName={firstName}
            userLastName={lastName}
            primaryEmail={primaryEmail}
            saveUser={saveUser}
            isSavingUser={isSavingUser}
          />
        </Grid>
        <Grid item xs={12} md={7} order={{ xs: 2, md: 3 }}>
          <PersonalCommunications
            userEmail={secondaryEmail}
            userPhoneNumber={phone}
            saveUser={saveUser}
            isSavingUser={isSavingUser}
          />
        </Grid>
        {!config.sessions.hideSessions && (
          <Grid item xs={12} md={5} order={{ xs: 3, md: 2 }}>
            <Sessions onClick={openModal} availableTokens={availableTokens} />
            <Modal isOpen={isOpenModal} onClose={closeModal}>
              <RequestSessions onClose={closeModal} />
            </Modal>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MyAccount;
