import styled from '@emotion/styled';
import { Button, ButtonBase, Card, Divider, Typography } from '@mui/material';

export const TitleText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-bottom: 42px;
  @media screen and (max-width: 600px) {
    margin-top: 32px;
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const BookingCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  padding-top: 28px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 24px;
`;

export const BrandImage = styled.img`
  border-radius: 15px;
`;

export const TextContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
`;

export const HeadingText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const BodyText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const SupportCTA = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background: ${({ theme }) => theme.palette.primary.main};
  text-transform: none;
`;

export const LocationText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ModalText = styled.a`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.main};
  &:hover {
    cursor: pointer;
  }
`;

export const CancellationModalText = styled(ButtonBase)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.error.main};
  &:hover {
    cursor: pointer;
  }
`;

export const InlineBodyText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const CancellationContainer = styled.div`
  text-align: center;
  padding: 12px 0px;
`;
