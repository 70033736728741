import React, { useCallback } from 'react';

import { useTheme } from '@emotion/react';

import InPersonIcon from '../../icons/InPersonIcon';
import PhoneIcon from '../../icons/PhoneIcon';
import VideoIcon from '../../icons/VideoIcon';

import { AppointmentChip, ClicakableAppointmentChip } from './AppointmentTypeChip.styles';

interface AppointmentTypeChipProps {
  type: string;
  label?: string;
  stroke?: string;
  selected?: boolean;
  onClick?: (type: string) => void;
}

const AppointmentTypeChip = ({
  type,
  label,
  stroke = '',
  selected,
  onClick,
}: AppointmentTypeChipProps) => {
  const handleOnClick = useCallback((type: string) => {
    if (onClick) {
      onClick(type);
    }
  }, []);
  const theme = useTheme();

  const Icon = () => {
    const defaultStroke = stroke === '' ? theme.palette.primary.main : stroke;
    switch (type.trim()) {
      case 'In Person':
        return <InPersonIcon stroke={defaultStroke} />;
      case 'Video':
        return <VideoIcon stroke={defaultStroke} />;
      case 'Phone':
        return <PhoneIcon stroke={defaultStroke} />;
      default:
        return <InPersonIcon stroke={defaultStroke} />;
    }
  };

  return onClick ? (
    <ClicakableAppointmentChip
      variant={selected ? 'outlined' : 'filled'}
      icon={<Icon />}
      label={label}
      onClick={() => handleOnClick(type)}
    />
  ) : (
    <AppointmentChip variant="filled" icon={<Icon />} label={label} />
  );
};

export default AppointmentTypeChip;
