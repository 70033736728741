import React, { useCallback } from 'react';

import { useTheme } from '@emotion/react';
import { Button, Container, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useProvider } from '../../api';
import Booking from '../../components/Booking/Booking';
import ProviderDetail from '../../components/ProviderDetail/ProviderDetail';
import IconLeftArrow from '../../icons/IconLeftArrow';

import { ProviderDetailsContainer } from './ProviderDetails.styles';

const content = {
  backCTAText: 'Back to Support',
};

const ProviderDetails = () => {
  const { providerId } = useParams();
  const { provider } = useProvider(providerId ?? '');
  const navigate = useNavigate();
  const theme = useTheme();

  const handleOnClickBack = useCallback(() => {
    navigate('/providers');
  }, []);

  return !provider ? (
    <></>
  ) : (
    <ProviderDetailsContainer>
      <Container
        sx={{
          marginTop: '20px',
          marginBottom: '20px',
          '@media (max-width: 600px)': { padding: 0 },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ '@media (max-width: 600px)': { marginLeft: '22px' } }}>
            <Button
              onClick={handleOnClickBack}
              startIcon={<IconLeftArrow stroke={theme.palette.primary.main} />}
            >
              {content.backCTAText}
            </Button>
          </Grid>
          <Grid item lg={8} md={12} sx={{ '@media (max-width: 600px)': { textAlign: 'center' } }}>
            <ProviderDetail data={provider} />
          </Grid>
          <Grid item lg={4} md={12} sx={{ width: '100%' }}>
            <Booking provider={provider} />
          </Grid>
        </Grid>
      </Container>
    </ProviderDetailsContainer>
  );
};

export default ProviderDetails;
