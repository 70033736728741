import styled from '@emotion/styled';

export const Title = styled.h1`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  @media screen and (min-width: 600px) {
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
  }
`;
