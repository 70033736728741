import styled from '@emotion/styled';
import { Card, Divider, Grid, Link, Typography } from '@mui/material';

export const TitleText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  @media screen and (max-width: 899px) {
    margin-top: 32px;
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const EmptyBookingCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 60px;
  @media screen and (max-width: 600px) {
    padding-top: 48px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const BookingCard = styled(Card)`
  max-height: 782px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  @media screen and (max-width: 600px) {
    padding-top: 26px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const BrandImage = styled.img`
  border-radius: 15px;
`;

export const TextContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const HeadingText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
`;

export const BodyText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
`;

export const BookingGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

export const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 18px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const BioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const BioText = styled(Typography)`
  padding-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  padding-left: 12px;
  text-align: end;
  @media screen and (max-width: 600px) {
    padding-left: 0px;
    text-align: start;
  }
`;

export const DateTimeText = styled(Typography)`
  padding-top: 2px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 10px;
  @media screen and (max-width: 600px) {
    padding-bottom: 4px;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const LinkText = styled(Link)`
  padding-top: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const LoadedAppointmentsCard = styled(Card)`
  text-align: center;
  padding-top: 16px;
  padding-bottom: 32px;
`;

export const BoldText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;
