import React, { useState } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';

import { useTokens } from '../../api';

import {
  BodyText,
  SubtitleText,
  TitleText,
  StyledDivider,
  QuestionText,
  SubmitButton,
  SubmitContainer,
} from './RequestSessions.styles';

const content = {
  title: 'Request more sessions',
  subtitle:
    'Please let us know the main reason for your additional sessions request to help Ignite gain approval from your organisation.',
  body: 'This request comes to the Ignite team for review and is taken to your organisation anonymously for consideration. We do not share your name, only the number of sessions you have used and requested and your main reason. If your orgnasiation requests further information, we will gain your consent before providing it.',
  sessionsAmountText: 'How many sessions would you like?',
  sessionsOptions: [
    { id: 0, label: '1 session', value: '1' },
    { id: 1, label: '2 sessions', value: '2' },
    { id: 2, label: '3 sessions', value: '3' },
    { id: 3, label: '4 sessions', value: '4' },
  ],
  requestReasonText: 'Why would you like more sessions?',
  reasons: [
    { id: 0, label: 'To continue the support I have been getting', value: 'continue' },
    { id: 1, label: 'To book a different type of provider', value: 'different' },
    { id: 2, label: 'Someone in the family needs access to support', value: 'family' },
    { id: 3, label: 'For an issue that has just come up', value: 'issue' },
    { id: 4, label: 'Other', value: 'other' },
  ],
  reasonsError: 'Please select your main reason for your request for additional sessions',
  submitCTAText: 'Submit request',
};

type FormValues = {
  sessions: string;
  reason: string;
};

const DEFAULT_FORM_VALUES = {
  sessions: '1',
  reason: '',
};

interface RequestSessionsProps {
  onClose: () => void; // Defining the type of the onClose prop as a function that returns void
}

const RequestSessions = ({ onClose }: RequestSessionsProps) => {
  const [formValues, setFormValues] = useState<FormValues>(DEFAULT_FORM_VALUES);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { requestTokens } = useTokens();

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (name === 'reason' && error) {
      setError(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formValues.reason) {
      setError(true);
    } else {
      setIsLoading(true);
      requestTokens({ tokenAmount: parseInt(formValues.sessions), reason: formValues.reason })
        .then(() => {
          onClose();
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const isError = error && !formValues.reason;

  return (
    <form onSubmit={handleSubmit}>
      <TitleText>{content.title}</TitleText>
      <SubtitleText>{content.subtitle}</SubtitleText>
      <BodyText>{content.body}</BodyText>
      <StyledDivider />
      <QuestionText>{content.sessionsAmountText}</QuestionText>
      <FormControl fullWidth>
        <Select id="sessions" name="sessions" value={formValues.sessions} onChange={handleChange}>
          {content.sessionsOptions.map((sessionsOption) => (
            <MenuItem key={sessionsOption.id} value={sessionsOption.value}>
              {sessionsOption.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <QuestionText>{content.requestReasonText}</QuestionText>
      <FormControl>
        <RadioGroup
          aria-label="reason"
          name="reason"
          value={formValues.reason}
          onChange={handleChange}
        >
          {content.reasons.map((reason) => (
            <FormControlLabel
              key={reason.id}
              value={reason.value}
              control={<Radio sx={{ color: isError ? 'red' : '' }} />}
              label={reason.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {isError && <FormHelperText error={isError}>{content.reasonsError}</FormHelperText>}
      <SubmitContainer>
        <SubmitButton variant="contained" type="submit" disabled={isLoading}>
          {isLoading ? (
            <CircularProgress size={24} style={{ color: 'white', marginRight: '10px' }} />
          ) : null}
          {content.submitCTAText}
        </SubmitButton>
      </SubmitContainer>
    </form>
  );
};

export default RequestSessions;
