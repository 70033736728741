import { ReactComponent as BeliefsIcon } from '../../assets/domains/Beliefs.svg';
import { ReactComponent as BodyIcon } from '../../assets/domains/Body.svg';
import { ReactComponent as BoxIcon } from '../../assets/domains/Box.svg';
import { ReactComponent as ConnectednessIcon } from '../../assets/domains/Connectedness.svg';
import { ReactComponent as CoronavirusIcon } from '../../assets/domains/Coronavirus.svg';
import { ReactComponent as CoronavirusWhiteIcon } from '../../assets/domains/CoronavirusWhite.svg';
import { ReactComponent as CultureIcon } from '../../assets/domains/Culture.svg';
import { ReactComponent as FinancesIcon } from '../../assets/domains/Finances.svg';
import { ReactComponent as HabitsIcon } from '../../assets/domains/Habits.svg';
import { ReactComponent as HomeIcon } from '../../assets/domains/Home.svg';
import { ReactComponent as IdentityIcon } from '../../assets/domains/Identity.svg';
import { ReactComponent as MindIcon } from '../../assets/domains/Mind.svg';
import { ReactComponent as OccupationIcon } from '../../assets/domains/Occupation.svg';
import { ReactComponent as RecreationIcon } from '../../assets/domains/Recreation.svg';
import { ReactComponent as RelationshipsIcon } from '../../assets/domains/Relationships.svg';
import { ReactComponent as WellplaceIcon } from '../../assets/domains/Wellplace.svg';
import { ReactComponent as WellplaceLogoIcon } from '../../assets/domains/WellplaceLogo.svg';

export type IconType = React.FC<React.SVGProps<SVGSVGElement>>;

export type IconMap = { [key: string]: IconType };

export const DomainIcons: IconMap = {
  Beliefs: BeliefsIcon,
  Body: BodyIcon,
  Box: BoxIcon,
  Connectedness: ConnectednessIcon,
  Coronavirus: CoronavirusIcon,
  CoronavirusWhite: CoronavirusWhiteIcon,
  Culture: CultureIcon,
  Finances: FinancesIcon,
  Habits: HabitsIcon,
  Home: HomeIcon,
  Identity: IdentityIcon,
  Mind: MindIcon,
  Occupation: OccupationIcon,
  Recreation: RecreationIcon,
  Relationships: RelationshipsIcon,
  Wellplace: WellplaceIcon,
  WellplaceLogo: WellplaceLogoIcon,
};
