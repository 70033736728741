import { IconProps } from './Icons.types';

const InPersonIcon = ({
  fill = 'none',
  width = '13',
  height = '15',
  stroke = '#0B112C',
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill}>
    <path stroke={stroke} d="M6.5 7.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z" clipRule="evenodd" />
    <path stroke={stroke} d="M1 13a5.5 5.5 0 1 1 11 0v1.5H1V13Z" />
  </svg>
);

export default InPersonIcon;
