import { IconProps } from './Icons.types';

const IconLeftArrow = ({
  fill = 'none',
  width = '8',
  height = '14',
  stroke = '#3F51B5',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70996 2.11996L3.11996 6.70996L7.70996 11.3L6.28996 12.71L0.289961 6.70996L6.28996 0.709961L7.70996 2.11996Z"
      stroke={stroke}
    />
  </svg>
);

export default IconLeftArrow;
