import React, { useCallback } from 'react';

import { useTheme } from '@emotion/react';
import { Avatar, Card, CardActionArea, Grid, Stack, Tooltip } from '@mui/material';

import { useFeatureFlags, FeatureFlag } from '../../api/featureFlagsProvider';
import config from '../../config.json';
import LocationIcon from '../../icons/LocationIcon';
import { Provider as IProvider } from '../../types/Provider';
import AppointmentTypeChip from '../AppointmentTypeChip/AppointmentTypeChip';
import DomainChip from '../Domains/DomainChip';

import {
  AvatarContainer,
  BioText,
  LabelText,
  LocationText,
  NameText,
  SpecialitiesChip,
  StyledDivider,
} from './Provider.styles';

interface ProviderProps {
  data: IProvider;
  onSelect: (providerId: string) => void;
}

const Provider = ({ data, onSelect }: ProviderProps) => {
  const {
    firstName,
    lastName,
    type,
    avatar,
    suburb,
    city,
    shortDescription,
    specialties,
    bookingTypesAvailable,
    providerId,
    domains,
  } = data;
  const initials = `${firstName[0]}${lastName[0]}`;

  const handleOnClick = useCallback(() => {
    onSelect(providerId);
  }, [onSelect, providerId]);
  const featureFlags = useFeatureFlags();
  const domainsofwellbeingEnabled = featureFlags?.some(
    (flag: FeatureFlag) => flag.name === 'domainsofwellbeing' && flag.enabled
  );
  const theme = useTheme();

  return (
    <Card onClick={handleOnClick}>
      <CardActionArea sx={{ padding: '18px', paddingTop: '24px', paddingBottom: '15px' }}>
        <AvatarContainer>
          <Avatar
            src={avatar}
            alt={initials}
            sx={{ bgcolor: theme.palette.primary.main, width: 80, height: 80 }}
          >
            {!avatar && initials.toUpperCase()}
          </Avatar>
          <Grid
            container
            alignItems="flex-start"
            direction="column"
            justifyContent="space-between"
            sx={{
              paddingLeft: '16px',
              '@media (max-width: 410px)': { maxWidth: 'calc(100vw - 150px)' },
            }}
          >
            <Grid item sx={{ width: '100%' }}>
              <NameText noWrap>{`${firstName} ${lastName}`}</NameText>
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <BioText noWrap>{`${type}`}</BioText>
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <Grid container direction="row">
                <Grid item sx={{ marginRight: '5px', marginTop: '3px' }}>
                  <LocationIcon fill={theme.palette.primary.main} />
                </Grid>
                <Grid item xs={10}>
                  <LocationText noWrap>{suburb ? `${suburb}, ${city}` : city}</LocationText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AvatarContainer>
        <StyledDivider />
        <BioText>{shortDescription}</BioText>

        {domainsofwellbeingEnabled && (
          <>
            <StyledDivider />
            <LabelText>Domains</LabelText>
            {domains.slice(0, 3).map((domain, index) => {
              // If there are more than 3 items and this is the third item, show the "+n more" chip
              if (domains.length > 3 && index === 2) {
                return (
                  <Tooltip
                    key="more"
                    title={
                      <div>
                        {domains.slice(3).map((hiddenDomain) => (
                          <div key={hiddenDomain}>{hiddenDomain}</div>
                        ))}
                      </div>
                    }
                  >
                    <SpecialitiesChip label={`+${domains.length - 3} more`} variant="outlined" />
                  </Tooltip>
                );
              }
              // Otherwise, show the domain chip
              return <DomainChip key={domain} text={domain} />;
            })}
          </>
        )}
        <StyledDivider />
        {!config.providers.hideSpecialties && (
          <>
            <LabelText>Specialties</LabelText>
            {specialties.map((speciality, index) => {
              if (index === 5) {
                return (
                  <SpecialitiesChip
                    key={speciality}
                    label={`+${specialties.length - 5} more`}
                    variant="outlined"
                  />
                );
              } else if (index > 5) {
                return null;
              }
              return <SpecialitiesChip key={speciality} label={speciality} variant="outlined" />;
            })}
            <StyledDivider />
          </>
        )}
        <Stack direction="row">
          {bookingTypesAvailable.map((bookingType) => (
            <AppointmentTypeChip key={bookingType} type={bookingType} />
          ))}
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default Provider;
