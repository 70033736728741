import styled from '@emotion/styled';
import { Container, Paper } from '@mui/material';

export const StyledPaper = styled(Paper)`
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;

  @media screen and (max-width: 600px) {
    position: relative;
    margin-top: 56px;
    width: 100%;
    padding: 16px;
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ChildContainer = styled(Container)`
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding: 0px;

  @media screen and (max-width: 600px) {
    padding-bottom: 80px;
  }
`;
