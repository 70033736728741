export interface OptionType {
  group: string;
  name: string;
}

export interface GroupedFilterProps {
  options: OptionType[];
  label: string;
  onChange: (option: string[]) => void;
  values: string[];
  renderIcon: (option: OptionType) => JSX.Element;
}

export interface Domains {
  health: string[];
  life: string[];
  meaning: string[];
}

export type DomainKey = 'health' | 'life' | 'meaning';

export const domains: Domains = {
  health: ['Mind', 'Body', 'Habits', 'Home'],
  life: ['Relationships', 'Occupation', 'Finances', 'Recreation'],
  meaning: ['Identity', 'Beliefs', 'Culture', 'Connectedness'],
};
