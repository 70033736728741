import { IconProps } from './Icons.types';

const VideoIcon = ({
  fill = 'none',
  width = '17',
  height = '12',
  stroke = '#030723',
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill}>
    <rect width="11" height="10" x="1" y="1" stroke={stroke} rx="2" />
    <path
      stroke={stroke}
      d="m12 5.933 3.204-2.35a.5.5 0 0 1 .796.404v4.728a.5.5 0 0 1-.796.403L12 6.768"
    />
  </svg>
);

export default VideoIcon;
