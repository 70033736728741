import React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';
import { Avatar, Grid } from '@mui/material';
import { addHours, format } from 'date-fns';

import { useUser } from '../../api';
import { Appointment } from '../../types/Appointment';
import { appendComma } from '../../utils/format';
import AppointmentTypeChip from '../AppointmentTypeChip/AppointmentTypeChip';

import {
  AvatarContainer,
  BioText,
  BodyText,
  LabelText,
  LinkText,
  NameText,
  StyledDivider,
  VirutalMeetingCTA,
} from './AppointmentCard.styles';

interface AppointmentProps {
  appointment: Appointment;
  hideAvatar?: boolean;
}

const content = {
  viewProfileText: 'View profile',
  appointmentTypeLabel: 'Appointment Type',
  dateTimeLabel: 'Date & Time',
  locationLabel: 'Location',
  virtualMeeting: 'Virtual meeting',
};

const AppointmentCard = ({ appointment, hideAvatar }: AppointmentProps) => {
  const {
    providerImageUrl,
    providerFirstName,
    providerLastName,
    providerType,
    providerId,
    appointmentType,
    appointmentStart,
    addressLine1,
    suburb,
    city,
    meetingRoom,
  } = appointment;

  const initials = `${providerFirstName[0]}${providerLastName[0]}`;
  const date = new Date(appointmentStart);
  const { user } = useUser();

  const handleOnClick = () => {
    if (meetingRoom && user) {
      const { firstName } = user;
      window.open(
        `${process.env.REACT_APP_VIDEO_SITE}?userid=${firstName}&roomid=${meetingRoom}`,
        '_blank'
      );
    }
  };

  const renderAppointmentLocation = () => {
    switch (appointmentType.trim()) {
      case 'In Person':
        return <BodyText>{`${appendComma(addressLine1)} ${appendComma(suburb)} ${city}`}</BodyText>;
      case 'Video':
        return meetingRoom ? (
          <VirutalMeetingCTA
            variant="outlined"
            size="large"
            color="secondary"
            endIcon={<LaunchIcon />}
            onClick={handleOnClick}
          >
            {content.virtualMeeting}
          </VirutalMeetingCTA>
        ) : (
          <BodyText>{appointmentType}</BodyText>
        );
      case 'Phone':
        return <BodyText>{appointmentType}</BodyText>;
      default:
        return <BodyText>{`${appendComma(addressLine1)} ${appendComma(suburb)} ${city}`}</BodyText>;
    }
  };

  return (
    <>
      {!hideAvatar && (
        <>
          <AvatarContainer>
            <Avatar
              src={providerImageUrl}
              alt={initials}
              sx={{ bgcolor: (t) => t.palette.primary.main, width: 80, height: 80 }}
            >
              {!providerImageUrl && initials.toUpperCase()}
            </Avatar>
            <Grid
              container
              alignItems="flex-start"
              direction="column"
              justifyContent="space-between"
              sx={{
                paddingLeft: '16px',
                '@media (max-width: 410px)': { maxWidth: 'calc(100vw - 150px)' },
              }}
            >
              <Grid item sx={{ width: '100%' }}>
                <NameText noWrap>{`${providerFirstName} ${providerLastName}`}</NameText>
              </Grid>
              <Grid item sx={{ width: '100%' }}>
                <BioText noWrap>{`${providerType}`}</BioText>
              </Grid>
              <Grid item sx={{ width: '100%' }}>
                <LinkText to={`/providers/${providerId}`}>{content.viewProfileText}</LinkText>
              </Grid>
            </Grid>
          </AvatarContainer>
          <StyledDivider />
        </>
      )}
      <Grid container spacing={2}>
        <Grid item sm={5} xs={12}>
          <LabelText>{content.appointmentTypeLabel}</LabelText>
          <AppointmentTypeChip type={appointmentType} label={appointmentType} />
        </Grid>
        <Grid item sm={7} xs={12}>
          <LabelText>{content.dateTimeLabel}</LabelText>
          <BodyText>
            {format(date, 'PPPP')}
            <br />
            {format(date, 'p') + ' - ' + format(addHours(date, 1), 'p')}
          </BodyText>
        </Grid>
        <Grid item xs={12}>
          <LabelText>{content.locationLabel}</LabelText>
          {renderAppointmentLocation()}
        </Grid>
      </Grid>
    </>
  );
};

export default AppointmentCard;
