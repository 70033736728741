import styled from '@emotion/styled';
import { Button, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const BioText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const BodyText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const LabelText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const LinkText = styled(Link)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const NameText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  @media screen and (min-width: 600px) {
    font-size: 20px;
  }
`;

export const VirutalMeetingCTA = styled(Button)`
  color: ${({ theme }) => theme.palette.secondary.main},
  border-color: ${({ theme }) => theme.palette.secondary.main},
  '&:hover': {
    border-color: ${({ theme }) => theme.palette.secondary.main},
    background-color: ${({ theme }) => theme.palette.secondary.main}
  },
 `;
