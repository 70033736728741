import styled from '@emotion/styled';
import { Chip } from '@mui/material';

export const AppointmentChip = styled(Chip)(({ theme }) => ({
  padding: '8px 12px',
  background: 'transparent',
  borderRadius: '5px',

  '&.MuiChip-label': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.44px',
  },
  '&.MuiChip-outlined': {
    padding: '7px 11px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  '&:hover': {
    cursor: 'inherit',
  },
}));

export const ClicakableAppointmentChip = styled(Chip)(({ theme }) => ({
  padding: '8px 12px',
  background: 'transparent',
  borderRadius: '5px',

  '&.MuiChip-label': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.44px',
  },
  '&.MuiChip-outlined': {
    padding: '7px 11px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.light,
      background: theme.palette.primary.light,
    },
  },
  '&:hover': {
    background: theme.palette.primary.light,
  },
}));
