import React, { useCallback } from 'react';

import { Card } from '@mui/material';

import { useTokens } from '../../api';
import config from '../../config.json';

import {
  BodyText,
  RequestMoreButton,
  SessionsContainer,
  SessionsTitleText,
  StyledDivider,
  TitleText,
} from './Sessions.styles';

const content = {
  title: 'Your Sessions',
  sessionsDescription: `Use sessions to book ${config.providers.label} appointments.`,
  body1: 'You currently have a request for',
  sessionText: 'session',
  sessionsText: 'sessions',
  body2: 'under review',
  ctaText: 'Request more sessions',
};

const REQUESTED_STATUS = 'Requested';
const EMPTY_STATUS = '';
const EMPTY_TOKENS = { tokenAmount: 0, status: EMPTY_STATUS };
interface SessionsProps {
  availableTokens: number;
  onClick: () => void;
}

const Sessions = ({ availableTokens, onClick }: SessionsProps) => {
  const handleOnClick = useCallback(() => {
    onClick();
  }, [onClick]);

  const { data } = useTokens();
  const { tokenAmount, status } = (data && data[0]) || EMPTY_TOKENS;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        padding: '16px',
      }}
    >
      <TitleText>{content.title}</TitleText>
      <StyledDivider />
      <SessionsContainer>
        <SessionsTitleText>{`${availableTokens} ${
          availableTokens === 1 ? content.sessionText : content.sessionsText
        }`}</SessionsTitleText>
        <BodyText>{content.sessionsDescription}</BodyText>
        {status === REQUESTED_STATUS ? (
          <BodyText>{`${content.body1} ${tokenAmount} ${
            tokenAmount === 1 ? content.sessionText : content.sessionsText
          } ${content.body2}`}</BodyText>
        ) : (
          <RequestMoreButton variant="contained" fullWidth onClick={handleOnClick}>
            {content.ctaText}
          </RequestMoreButton>
        )}
      </SessionsContainer>
    </Card>
  );
};

export default Sessions;
