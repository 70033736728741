import React from 'react';

import { DomainIcons } from '../Domains/DomainIcons';

import { FilterContainer, FilterTitle } from './Filter.styles';
import GroupedFilter from './GroupedFilter';
import { OptionType, domains } from './types';

const formatGroupName = (groupName: string): string => {
  return `My ${groupName.charAt(0).toUpperCase() + groupName.slice(1)}`;
};

const transformDomainsToOptions = (): OptionType[] => {
  return Object.entries(domains).flatMap(([group, names]) =>
    names.map(
      (name: string): OptionType => ({
        group: formatGroupName(group),
        name,
      })
    )
  );
};

const content = {
  label: 'I Want Support With',
  placeholder: 'View all support areas',
  options: transformDomainsToOptions(),
};
interface DomainFilterProps {
  onChange: (option: string[]) => void;
  values: string[];
}

const DomainFilter: React.FC<DomainFilterProps> = ({ onChange, values }) => {
  const renderIcon = (option: OptionType) => {
    const Icon = DomainIcons[option.name] || <></>;
    return <Icon />;
  };
  const placeholderText = values.length > 0 ? `${values.length} selected` : content.placeholder;
  return (
    <FilterContainer>
      <FilterTitle>{content.label}</FilterTitle>
      <div style={{ minWidth: '300px' }}>
        <GroupedFilter
          options={content.options}
          label={placeholderText}
          onChange={onChange}
          values={values}
          renderIcon={renderIcon}
        />
      </div>
    </FilterContainer>
  );
};

export default DomainFilter;
